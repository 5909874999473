import { useEffect } from "react"
import { useState } from "react"

export default function Condition({privacy}) {
    const [active, setActive] = useState(true)

    useEffect(()=>{
        window.scrollTo(0, 0);
    })

  return (
    <div className="pb-60 md:w-10/12 w-11/12 mx-auto">
        <div className="mpc:w-[55%] w-4/5 mx-auto py-9 my-14">
          <h1 className=' md:text-4xl text-2xl font-bold text-center text-primary'>
                { privacy ? 'Privacy Policy' : 'Terms And Conditions' }
          </h1>
        </div>
        <div className="grid grid-cols-2 md:gap-7 gap-4 mb-14">
            <p onClick={()=> setActive(true)} 
            className={`py-4 rounded-t-2xl cursor-pointer px-6 md:text-3xl text-center text-xl bg-[#E5E3FF] text-gray-500 ${active && 'font-bold text-white bg-sec'}`} >FTR Store</p>
            <p onClick={()=> setActive(false)} 
            className={`py-4 rounded-t-2xl cursor-pointer px-6 md:text-3xl text-center text-xl bg-[#E5E3FF] text-gray-500 ${!active && 'font-bold text-white bg-sec'}`} >FTR Client</p>
        </div>
        <div className="mb-12">
            <h1 className="text-sec font-bold mb-4 md:text-3xl text-xl">
                Preamble:
            </h1>
            <p className="text-text md:text-2xl text-xl">
                The present Privacy policy concerning the protection of personal data is governed by the laws and regulations of the eligible countries mentioned below: <br /><br />
                Algeria: In view of the law 18-07 of 10-06-2018 relating to the protection of individuals in the processing of personal data.<br /><br />
                Morocco: In view of the Dahir n° 1-09-15 of 22 safar 1430 (18 February 2009) Decree the law n° 09-08 relating to the protection of individuals regarding the processing of personal data.<br /><br />
                Tunisia: Law n° 2004-63 of July 27, 2004 concerning the protection of personal data,
            </p>
        </div>
        <div>
            <h1 className="text-sec font-bold mb-4 md:text-3xl text-xl">
                Definition and nature of the personal data
            </h1>
            <p className="text-text md:text-2xl text-xl">
                During your use of our website and FTR Store application, we may ask you to communicate your
                personal data. <br /><br />
                The term "personal data" refers to all the data that allows an individual to be identified,
                which corresponds in particular to your name, first name, pseudonym, photograph, postal and
                e-mail addresses, phone number, date of birth, data relating to your transactions on the
                site or the applications provided by the company Fintechracy.            
            </p>
        </div>
    </div>
  )
}
