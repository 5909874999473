import { Link } from 'react-router-dom'
import images from '../../constants/images'
import data from './data'

export default function JoinApp({ lang }) {
  return (
    <div className='py-20 bg-sec md:mb-44 mt-36 mb-16'>
      <div className=' scale-[70%]  container mx-auto px-10 ph:px-5 pt-5 flexBetween gap-6 pc:flex-col'>
        <p className='w-8/12 text-[42px] pc:text-xl pc:w-[75%]  pc:leading-[2.5] pc:text-center  font-medium leading-[1.4] text-white'>{data[lang].joinComm}</p>
        <div className='w-2/12 pc:w-6/12'>
            <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.pos&pcampaignid=web_share'} 
            className='flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg mb-4'>
               <img src={images.playStore} className=' w-14 pc:w-10' alt="" />
                <span className='text-2xl pc:text-sm text-white font-bold'>Play Store</span>
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.pos&pcampaignid=web_share'} 
            className='flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg'>
               <img src={images.appStore} className=' w-14 pc:w-10' alt="" />
               <span className='text-2xl pc:text-sm text-white font-bold'>App Store</span>
            </Link>
        </div>
      </div>
    </div>
  )
}