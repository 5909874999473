import { Link, NavLink, useLocation } from 'react-router-dom'
import images from '../constants/images'
import data from './data'
import { useEffect, useRef, useState } from 'react'
import DownloadBtn from './DownloadBtn'

export default function Navbar({lang,setLang}) {
  const [path, setPath] = useState('')
  const [openlang, setOpenLang] = useState(false)
  const [openNav, setOpenNav] = useState(false)
  const location = useLocation()
  const changeLang = (lang)=>{
    localStorage.setItem('lang',lang)
    setLang(lang)
    if(lang === 'ar') {
      document.body.classList.add('ar')
    } else {
      document.body.classList.remove('ar')
    }
  }
  const closeNav = ()=>{
    setOpenNav(false)
  }

  useEffect(()=>{
    setPath(location.pathname.split('/')[1])
  }, [location.pathname])

  const navRef = useRef(null);
  const langRef = useRef(null);

  useEffect(() => {
    const closeNavOnOutsideClick = (event) => {
      if (openNav && navRef.current && !navRef.current.contains(event.target)) {
        setOpenNav(false);
      }
    };
    const closeLangOnOutsideClick = (event) => {
      if (openlang && langRef.current && !langRef.current.contains(event.target)) {
        setOpenLang(false);
      }
    };

    document.body.addEventListener('click', closeNavOnOutsideClick);
    document.body.addEventListener('click', closeLangOnOutsideClick);

    return () => {
      document.body.removeEventListener('click', closeNavOnOutsideClick);
      document.body.removeEventListener('click', closeLangOnOutsideClick);
    };
  }, [openNav,openlang]);

  return (
    <>
    <img src={images.bluebg} className='absolute top-0 left-0 right-0 w-full -z-20' alt="" />
    <nav ref={navRef} className='boxShadow   w-[90%] md:w-[99%]  h-12 md:h-20  mx-auto       z-50  md:scale-[90%]    bg-white rounded-full flexBetween md:pt-6 py-4 md:px-4 px-5 mt-8 md:mb-5 mb-12'>
      <Link to={'/'} className=" md:w-[186px] w-[97px]">
        <img src={images.logo} alt="" />
      </Link>
      <div className={`flexCenter resNav transition-opacity ease-in-out duration-300 ${openNav ? 'pc:max-h-max pc:opacity-100' : 'pc:max-h-0 pc:overflow-hidden pc:opacity-0'}`}>
        {
          data[lang].links.map((link,index) => (
          index+1 !== data[lang].links.length ?
          <NavLink
            key={link.path}
            className={`text-[1.05rem] pc:text-[15px] p-3 pc:p-0 text-primary hover:text-sec ${path === link.path && 'text-sec font-bold'}
             hover:text-sec pc:w-full pc:text-center pc:block`} 
            onClick={()=> setPath(link.path,closeNav())}
            to={`/${link.path}`}>
            {link.link}
          </NavLink>
          :
          <a
            key={link.path}
            className={`text-lg pc:text-[15px] p-3 pc:p-0 text-primary hover:text-sec ${path === link.path && 'text-sec font-bold'}
             hover:text-sec pc:w-full pc:text-center pc:block`}
            onClick={()=> setPath(link.path,closeNav())}
            href={`#contact`}>
            {link.link}
          </a>
          )
          )
        }
      </div>
      <div>
        <img src={openNav ? images.closeMenu : images.menu} alt="" className='hidden md: text-xl pc:block cursor-pointer'
        onClick={()=>setOpenNav(!openNav)} />
      </div>
      <div className='flexCenter mpc:gap-4 gap-2'>
        <div ref={langRef}
        onClick={()=> setOpenLang(!openlang)}
        className='flexCenter mpc:gap-2 gap-1 cursor-pointer relative'
        >
          <img src={images.lang} className=' md:w-[25px] w-[19px]' alt=""/>
          <img src={images.arrowb} className=' md:w-[10px] w-[8px]' alt="" />
          <div className={`absolute top-12 bg-[#EFEFEF] rounded-md overflow-hidden ${!openlang && 'hidden'}`}>
            <div className='text-primary px-3 py-2 transition-all hover:font-bold' onClick={()=>changeLang('en')}>English</div>
            <div className='text-primary px-3 py-2 transition-all hover:font-bold' onClick={()=>changeLang('fr')}>French</div>
            <div className='text-primary px-3 py-2 transition-all hover:font-bold' onClick={()=>changeLang('ar')}>Arabic</div>
          </div>
        </div>
        <DownloadBtn nav lang={lang} />
      </div>
    </nav>
    </>
  )
}