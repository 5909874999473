import React, { useEffect } from 'react'
import images from '../../constants/images'
import { useParams } from 'react-router-dom'
import data from './data'
import Contact from '../../components/Contact'
import JoinApp from './JoinApp'

export default function BlogDetails({lang}) {
    const { id } = useParams()
    useEffect(()=>{
      window.scrollTo(0, 0);
    })
    const blogImages = [
        images.blog1,
        images.blog2,
        images.blog3,
        images.blog4,
        images.blog5,
        images.blog6,
        images.blog7,
        images.blog8,
        images.blog9,
        images.blog10,
        images.blog11,
        images.blog12,
    ]
  return (
    <>
    <div className='md:w-2/3 w-11/12 mx-auto  scale-100 md:scale-[90%]  my-0 md:-my-[10%]  '>
        <p className='bg-[#E5E3FF] md:text-xl text-sm text-gray-500 w-fit py-4 px-6 rounded-full mx-auto mb-6'>{id % 2 !== 0 ? data[lang].blogTypeStore : data[lang].blogTypeClient}</p>
        <div className='md:text-5xl text-center text-2xl font-bold md:leading-[80px] leading-[38px]'>
          <span className='text-primary'>{data[lang].blogTitle}</span>
        </div>
        <img src={blogImages[id-1]} className='w-full my-16' alt="" />
        {
            [1,2,3,4,5,6].map( i => (
                <div key={i} className='md:mb-12 mb-7'>
                    { i !== 1 && <h2 className='text-sec md:text-[28px] md:text-start text-center text-2xl font-bold md:mb-12 mb-7'>{i-1}. {data[lang].blogDescTitle}</h2> }
                    <p className='text-text md:text-[26px] md:text-start text-center text-[19px]'>{data[lang].blogDesc}</p>
                </div>
            ))
        }
    </div>
    <JoinApp lang={lang} id={id} />
    <Contact lang={lang} />
    </>
  )
}