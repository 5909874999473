import React from 'react'
import data from './data'
import BenCard from '../../components/BenCard'
import { Link } from 'react-router-dom'

export default function Benifits({lang}) {
  return (
    <>
    <div className='container mx-auto px-10  md:-mt-44  scale-100   md:scale-[70%]    ph:px-5  '>
      <div className='md:mt-36 md:mb-20 mt-20 mb-10'>
        <h1 className='md:text-[38px]  text-[25px] font-bold text-center text-primary'>
          {data[lang].benTitle1} <span className='text-sec'>{data[lang].benTitle2}</span>
        </h1>
        <p className='minTitle    md:w-2/3'>{data[lang].benMinTitle1} <Link className="text-sec font-bold">{data[lang].seeMore}</Link></p>
      </div>
        <div className='flex mpc:justify-between  justify-center mpc:flex-row flex-col gap-5'>
        {
          data[lang].benCards1.map(item => <BenCard item={item} key={item.title} />)
        }
        </div>
    </div>
    <div className='container md:-my-44 scale-100   md:scale-[70%] mx-auto px-10 ph:px-5 pt-5  '>
      <div className="md:mt-36 md:mb-20 mt-20 mb-10">
        <h1 className=' md:text-[38px] text-[25px] font-bold text-center text-primary'>
            {data[lang].benTitle3} <span className='text-sec'>{data[lang].benTitle4}</span>
        </h1>
        <p className='minTitle md:text-center   md:w-2/3'>{data[lang].benMinTitle2} <Link className="text-sec font-bold">{data[lang].seeMore}</Link></p>
      </div>
        <div className='flex mpc:justify-between justify-center mpc:flex-row flex-col gap-5'>
        {
          data[lang].benCards2.map(item => <BenCard item={item} key={item.title} />)
        }
        </div>
    </div>
    </>
  )
}
