import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/home';
import { useEffect, useState } from 'react';
import FTRBusiness from './pages/FTR business';
import FTRClient from './pages/FTR Client';
import FTRStore from './pages/FTR Store';
import BlogPage from './pages/blog';
import BlogDetails from './pages/blog/BlogDetails';
import Footer from './components/Footer';
import Condition from './pages/Using Conditions/Condition';
import Faq from './pages/Using Conditions/Faq';
import UserDeletion from './pages/Using Conditions/UserDeletion';

function App() {
  const [lang, setLang] = useState('en');
  useEffect(() => {
    if (localStorage.getItem('lang')) {
      setLang(localStorage.getItem('lang'));
      if (lang === 'ar') {
        document.body.classList.add('ar');
      } else {
        document.body.classList.remove('ar');
      }
    }
  }, [lang]);
  return (
    <div Classname={` px-20  `}>
      <BrowserRouter>
        <Navbar lang={lang} setLang={setLang} />
        <Routes>
          <Route path="/" element={<Home lang={lang} />} />
          <Route path="/ftr-business" element={<FTRBusiness lang={lang} />} />
          <Route path="/ftr-client" element={<FTRClient lang={lang} />} />
          <Route path="/ftr-store" element={<FTRStore lang={lang} />} />
          <Route path="/blogs" element={<BlogPage lang={lang} />} />
          <Route
            path="/blogDetails/:id"
            element={<BlogDetails lang={lang} />}
          />
          <Route
            path="/termsAndConditions"
            element={<Condition lang={lang} />}
          />
          <Route
            path="/privacyPolicy"
            element={<Condition privacy lang={lang} />}
          />
          <Route path="/faq" element={<Faq lang={lang} />} />
          <Route path="/user-deletion" element={<UserDeletion lang={lang} />} />
        </Routes>
        <Footer lang={lang} />
      </BrowserRouter>
    </div>
  );
}

export default App;
