import images from '../../constants/images'
import data from './data'

export default function Business({ lang }) {
  return (
    <div>
        <img src={images.ftrStore7} className='md:mt-60 md:mb-40 mb-14 mt-20 w-full md:h-auto h-72' alt="" />
        <div className='container  mx-auto px-10 ph:px-5 pt-5 pc:mb-14 mb-44'>
          <h1 className=' md:text-4xl text-2xl font-bold text-center text-primary'>
            {data[lang].BusinessTitle1} <span className='text-sec'>{data[lang].BusinessTitle2}</span>
          </h1>
          <p className='minTitle w-2/3 pc:w-full'>{data[lang].BusinessText}</p>
          <div className='scale-100  md:scale-[70%] grid-cols-2 gap-8 md:grid hidden mt-24'>
            <img src={images.ftrStore8} className='w-full' alt="" />
            <div className='grid grid-rows-2'>
              <div className='grid grid-cols-2 gap-6 mb-6'>
                <img src={images.ftrStore9} className='w-full h-full' alt="" />
                <img src={images.ftrStore10} className='w-full h-full' alt="" />
              </div>
              <img src={images.ftrStore11} className='w-full h-full' alt="" />
            </div>
          </div>
          <div className='scale-100  md:scale-90 mt-8 md:hidden block'>
            <div className='flex gap-4'>
              <img src={images.ftrStore8} className='w-3/5' alt="" />
              <div className='grid grid-rows-2 justify-between gap-6 w-2/5'>
                <img src={images.ftrStore9} className='w-full' alt="" />
                <img src={images.ftrStore10} className='w-full' alt="" />
              </div>
            </div>
            <img src={images.ftrStore11} className='w-full mt-7' alt="" />
          </div>
        </div>
    </div>
  )
}