import { useEffect } from 'react'
import Contact from '../../components/Contact'
import ImgTextSection from '../../components/ImgTextSection'
import Testimonail from '../../components/Testimonail'
import images from '../../constants/images'
import Greener from './Greener'
import JoinApp from './JoinApp'
import Landing from './Landing'
import data from './data'

export default function FTRClient({lang}) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
  return (
    <>
        <Landing lang={lang} />
        <ImgTextSection 
            title1={data[lang].secTitle1} 
            title2={data[lang].secTitle2}
            text={data[lang].secText1}
            img={images.imgText9}
            bottomImage={''}
            lang={lang}
            right
        />
        <ImgTextSection 
            title1={data[lang].secTitle3} 
            title2={data[lang].secTitle4}
            text={data[lang].secText2}
            img={images.imgText2}
            bottomImage={images.bi2}
            lang={lang}
        />
        <ImgTextSection 
            title1={data[lang].secTitle5} 
            title2={data[lang].secTitle6}
            text={data[lang].secText3}
            img={images.imgText8}
            bottomImage={''}
            lang={lang}
            right
        />
        <ImgTextSection 
            title1={data[lang].secTitle7} 
            title2={data[lang].secTitle8}
            text={data[lang].secText4}
            img={images.imgText6}
            bottomImage={images.bi4}
            lang={lang}
        />
        <ImgTextSection 
            title1={data[lang].secTitle9} 
            title2={data[lang].secTitle10}
            text={data[lang].secText5}
            img={images.imgText11}
            bottomImage={''}
            lang={lang}
            right
        />
        <ImgTextSection 
            title1={data[lang].secTitle11} 
            title2={data[lang].secTitle12}
            text={data[lang].secText6}
            img={images.imgText10}
            bottomImage={images.bi6}
            lang={lang}
        />
        <Greener lang={lang} />
        <JoinApp lang={lang} />
        <Testimonail client lang={lang} />
        <Contact lang={lang} />
    </>
  )
}
