const data = {
    "en": {
        "title1" : "Fintechracy Blog, Your Ultimate Resource for ",
        "title2" : "Modernizing Your Business and Going Green.",
        "textTitle" : `Running a successful business can be challenging, but with the Fintechracy Blog, 
        you'll find all the insights, tips, and trends for the digital transformation you need`,
        "blogTitle" : "5 Reasons why you need Fintechracy Point of sale application",
        "blogTypeStore" : "FTR Store app",
        "blogTypeClient" : "FTR Client",
        "blogText" : " Start using the Fintechracy app today and experience a new era of efficiency, sustainability, and growth. With our app, you can bid farewell to paper clutter, embrace personalized customer interactions, and harness the power of data-driven marketing. ",
        "blogDesc": `If you're a small merchant, you know that keeping track of sales, inventory, and customer data can be a daunting task. Fortunately, there's a solution that can help streamline your operations and provide you with valuable insights into your business: a point of sale (POS) system. In this article, we'll explore the top five reasons why you should consider using a POS system in your day-to-day operations.`,
        "blogDescTitle": "Streamline your operations",
        "joinCommClient" :"Ready to join us in making a difference too? Download now to embrace a paperless, eco-conscious lifestyle and help create a positive impact",
        "joinCommStore" :"Looking to elevate your business? Take the first step and download our app today!",
    },

    "fr": {
        "title1" : "Fintechracy Blog, Votre ressource ultime pour",
        "title2" : " moderniser votre business et adopter une approche écologique.",
        "textTitle" : `avec le Blog de Fintechracy, vous trouverez toutes les informations,
        astuces et tendances nécessaires pour la transformation numérique dont vous avez besoin.`,
        "blogTitle" : "5 raisons pour lesquelles vous avez besoin de FTR store ",
        "blogTypeStore" : "FTR Store app",
        "blogTypeClient" : "FTR Client",
        "blogText" : "Commencez à utiliser l'application Fintechracy dès aujourd'hui et découvrez une nouvelle ère d'efficacité, de durabilité et de croissance. Avec notre application, vous pouvez dire adieu au désordre du papier, adopter des interactions client personnalisées et exploiter la puissance du marketing axé sur les données.",
        "blogDesc": `Si vous êtes un petit commerçant, vous savez que suivre les ventes, l'inventaire et les données des clients peut être une tâche intimidante. Heureusement, il existe une solution qui peut vous aider à rationaliser vos opérations et à vous fournir des informations précieuses sur votre entreprise : un système de point de vente (POS). Dans cet article, nous explorerons les cinq principales raisons pour lesquelles vous devriez envisager d'utiliser un système POS dans vos opérations quotidiennes.`,
        "blogDescTitle": "Rationalisez vos opérations",
        "joinCommClient" :"Prêt à vous engager pour un impact positif ? Téléchargez dès maintenant pour adopter un mode de vie éco-responsable sans papier.",
        "joinCommStore" :"Prêt à donner un coup de pouce à votre boutique ? Commencez dès maintenant en téléchargeant notre application",
    },

    "ar": {
        "title1" : "مدونتنا ",
        "title2" : "رحلتك نحو النجاح والاستدامة",
        "textTitle" : "مع مدونة Fintechracy ستجدون هنا جميع المعلومات، الاستراتيجيات، وأحدث الصيحات لتحقيق التحول الرقمي",
        "blogTitle" : "5 اسباب تجعلك بحاجة إلى تطبيقنا",
        "blogTypeStore" : "FTR Store app",
        "blogTypeClient" : "FTR Client",
        "blogText" : "ابدأ استخدام تطبيقنا اليوم واستمتع بعصر جديد من الكفاءة، والاستدامة، والنمو. من خلال تطبيقنا، ستودع فوضى الأوراق إلى الأبد، وتتبنى تفاعلات مخصصة مع عملائك، وتستغل بقوة الإستراتيجيات التسويقية المبنية على البيانات.",
        "blogDesc": `إذا كنت تدير عملًا تجاريًا صغيرًا، فإنك تعلم أن تتبع المبيعات وإدارة المخزون ومعلومات العملاء يمكن أن يكون مهمة مرهقة. لحسن الحظ، هناك حلاً يمكن أن يساعدك في تبسيط عملياتك وتوفير معلومات قيمة حول عملك. في هذا المقال، سنتناول خمسة أسباب رئيسية تجعل من الضروري أن تفكر في استخدام نظام نقاط البيع في عملياتك اليومية`,
        "blogDescTitle": "نظم عملياتك بفعالية",
        "joinCommClient" :" انضم إلينا اليوم وكن جزءًا من تغيير إيجابي يؤثر على بيئتنا ومجتمعنا",
        "joinCommStore" :"هل تتطلع إلى تطوير عملك؟ اخطوا الخطوة الأولى وقم بتنزيل تطبيقنا اليوم",
    }
}

export default data