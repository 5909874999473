import logo from '../assets/logo.png'
import lang from '../assets/lang.png'
import arrowb from '../assets/arrowb.png'
import arrowbWhite from '../assets/arrowb-white.png'
import fb from '../assets/fb.png'
import ft from '../assets/ft.png'
import ft2 from '../assets/ft2.png'
import bluebg from '../assets/bluebg.png'
import menu from '../assets/menu.png'
import closeMenu from '../assets/closeMenu.png'
import landing from '../assets/landing.png'
import swiper1 from '../assets/swiper1.png'
import swiper2 from '../assets/swiper2.png'
import swiper3 from '../assets/swiper3.png'
import swiper4 from '../assets/swiper1.png'
import swiper5 from '../assets/swiper2.png'
import swiper6 from '../assets/swiper3.png'
import worldmap from '../assets/worldmap.png'
import star from '../assets/star.png'
import client1 from '../assets/client1.png'
import client2 from '../assets/client2.png'
import benCard1 from '../assets/benCard1.png'
import benCard2 from '../assets/benCard2.png'
import benCard3 from '../assets/benCard3.png'
import benCard4 from '../assets/benCard4.png'
import benCard5 from '../assets/benCard5.png'
import benCard6 from '../assets/benCard6.png'
import bigBlog from '../assets/bigCard.png'
import blogCrad1 from '../assets/blogCard1.png'
import blogCrad2 from '../assets/blogCard2.png'
import blogCrad3 from '../assets/blogCard3.png'
import blogCrad4 from '../assets/blogCard4.png'
import testimonial from '../assets/testimonial.png'
import testimonial0 from '../assets/testimonial0.png'
import starYellow from '../assets/starYellow.png'
import square from '../assets/square.png'
import contact1 from '../assets/contact1.png'
import contact2 from '../assets/contact2.png'
import linkdin from '../assets/linedin.png'
import facebook from '../assets/facebook.png'
import instagram from '../assets/instagram.png'
import imgText1 from '../assets/imgText1.png'
import imgText2 from '../assets/imgText2.png'
import imgText3 from '../assets/imgText3.png'
import imgText4 from '../assets/imgText4.png'
import imgText5 from '../assets/imgText5.png'
import imgText6 from '../assets/imgText6.png'
import imgText7 from '../assets/imgText7.png'
import imgText8 from '../assets/imgText8.png'
import imgText9 from '../assets/imgText9.png'
import imgText10 from '../assets/imgText10.png'
import imgText11 from '../assets/imgText11.png'
import bi1 from '../assets/bi1.png'
import bi2 from '../assets/bi2.png'
import bi3 from '../assets/bi3.png'
import bi4 from '../assets/bi4.png'
import bi5 from '../assets/bi5.png'
import bi6 from '../assets/bi6.png'
import mobile from '../assets/mobile.png'
import ftrClientFr from '../assets/ftrClientFr.png'
import ftrClientEn from '../assets/ftrClientEn.png'
import appStore from '../assets/app store.png'
import playStore from '../assets/Google Play Store.png'
import ftrClientBI from '../assets/ftrClientBI.png'
import tree from '../assets/tree.png'
import co2 from '../assets/co2.png'
import water from '../assets/water.png'
import ftrStore from '../assets/ftrStore.png'
import ftrStore1 from '../assets/ftrStore1.png'
import ftrStore2 from '../assets/ftrStore2.png'
import ftrStore3 from '../assets/ftrStore3.png'
import ftrStore4 from '../assets/ftrStore4.png'
import ftrStore5 from '../assets/ftrStore5.png'
import ftrStore6 from '../assets/ftrStore6.png'
import ftrStore7 from '../assets/ftrStore7.png'
import ftrStore8 from '../assets/ftrStore8.png'
import ftrStore9 from '../assets/ftrStore9.png'
import ftrStore10 from '../assets/ftrStore10.png'
import ftrStore11 from '../assets/ftrStore11.png'
import blog1 from '../assets/Rectangle 199.png'
import blog2 from '../assets/Rectangle 202.png'
import blog3 from '../assets/Rectangle 204.png'
import blog4 from '../assets/Rectangle 205.png'
import blog5 from '../assets/Rectangle 208.png'
import blog6 from '../assets/Rectangle 209.png'
import blog7 from '../assets/Rectangle 212.png'
import blog8 from '../assets/Rectangle 213.png'
import blog9 from '../assets/Rectangle 214.png'
import blog10 from '../assets/Rectangle 215.png'
import blog11 from '../assets/Rectangle 220.png'
import blog12 from '../assets/Rectangle 221.png'
import dropdownfb from '../assets/dropdownfb.png'
import ftrStoreLogo from '../assets/ftrStoreLogo.png'
import ftrClientLogo from '../assets/ftrClientLogo.png'
import vecToRight from '../assets/vecToRight.png'
import vecToLeft from '../assets/vecToLeft.png'
import t from '../assets/true.png'
import x from '../assets/x.png'

const images = {
    logo,
    lang,
    arrowb,
    arrowbWhite,
    menu,
    dropdownfb,
    ftrStoreLogo,
    ftrClientLogo,
    vecToRight,
    vecToLeft,
    closeMenu,
    landing,
    fb,
    bluebg,
    worldmap,
    star,
    client1,
    client2,
    benCard1,
    benCard2,
    benCard3,
    benCard4,
    benCard5,
    benCard6,
    bigBlog,
    blogCrad1,
    blogCrad2,
    blogCrad3,
    blogCrad4,
    testimonial,
    testimonial0,
    starYellow,
    swiper1,
    swiper2,
    swiper3,
    swiper4,
    swiper5,
    swiper6,
    square,
    contact1,
    contact2,
    linkdin,
    facebook,
    instagram,
    imgText1,
    imgText2,
    imgText3,
    imgText4,
    imgText5,
    imgText6,
    imgText7,
    imgText8,
    imgText9,
    imgText10,
    imgText11,
    bi1,
    bi2,
    bi3,
    bi4,
    bi5,
    bi6,
    mobile,
    ftrClientFr,
    ftrClientEn,
    playStore,
    appStore,
    ftrClientBI,
    tree,
    co2,
    water,
    ftrStore,
    ftrStore1,
    ftrStore2,
    ftrStore3,
    ftrStore4,
    ftrStore5,
    ftrStore6,
    ftrStore7,
    ftrStore8,
    ftrStore9,
    ftrStore10,
    ftrStore11,
    blog1,
    blog2,
    blog3,
    blog4,
    blog5,
    blog6,
    blog7,
    blog8,
    blog9,
    blog10,
    blog11,
    blog12,
    t,
    x,
    ft,
    ft2,
}

export default images