const data = {
  en: {
    links: [
      {
        link: 'Home',
        path: '',
      },
      {
        link: 'FTR Business',
        path: 'ftr-business',
      },
      {
        link: 'FTR Client',
        path: 'ftr-client',
      },
      {
        link: 'FTR Store',
        path: 'ftr-store',
      },
      {
        link: 'Blog',
        path: 'blogs',
      },
      {
        link: 'Contact us',
        path: 'contact-us',
      },
    ],
    download: 'Download',
    contact: 'Contact us',
    testimonialTitle1: 'What Our clients say',
    testimonialTitle3: 'about us',
    testimonialTitle2: 'What Our Partners say',
    testimonialText:
      "Fintechracy's POS system has been a game-changer for my boutique. It's like having a personal assistant for my clients. They love the seamless shopping experience, and I love the efficiency it brings to my business.",
    testimonialText2:
      "Fintechracy's POS system has been a game-changer for my boutique. It's like having a personal assistant for my clients. They love the seamless shopping experience, and I love the efficiency it brings to my business.",
    contactForm: [
      {
        label: 'First Name',
        placeholder: 'Write your First Name',
        value: 'firstname',
      },
      {
        label: 'Last Name',
        placeholder: 'Write your Last Name',
        value: 'lastname',
      },
      {
        label: 'Email',
        placeholder: 'Write your Email',
        value: 'email',
      },
      {
        label: 'Phone number',
        type: 'phone',
        placeholder: 'Your Phone Number',
        value: 'phonenumber',
      },
    ],
    message: 'Message',
    send: 'Send',
    footerText: `At Fintechracy, we build the digital
        bridge that connects businesses
        and clients, harnessing technology
        to enhance communication and efficiency.`,
    otherLinks: [
      {
        link: 'FAQ',
        path: 'faq',
      },
      {
        link: 'Terms & Conditions',
        path: 'termsAndConditions',
      },
      {
        link: 'Privacy Policy',
        path: 'privacyPolicy',
      },
      {
        link: 'Account Deletion Request',
        path: 'user-deletion',
      },
    ],
    linksTitle: 'Our Links',
    otherLinksTitle: 'Other Links',
    rights: 'All Rights Reserved @Fintechracy',
    dowNow: 'Download it now',
  },

  fr: {
    links: [
      {
        link: 'Accueil',
        path: '',
      },
      {
        link: 'FTR Business',
        path: 'ftr-business',
      },
      {
        link: 'FTR Client',
        path: 'ftr-client',
      },
      {
        link: 'FTR Store',
        path: 'ftr-store',
      },
      {
        link: 'Blog',
        path: 'blogs',
      },
      {
        link: 'Contactez-nous',
        path: 'contactez-nous',
      },
    ],
    download: 'Télécharger',
    contact: 'Contactez-nous',
    testimonialTitle1: 'Témoignages de nos clients',
    testimonialTitle2: 'Témoignages de nos partenaires',
    testimonialTitle3: '',
    testimonialText:
      "FTR Client a révolutionné ma boutique. C'est comme avoir un assistant personnel pour mes clients. Ils adorent l'expérience d'achat fluide.",
    testimonialText2:
      "Le système de point de vente de Fintechracy a révolutionné ma boutique. C'est comme avoir un assistant personnel pour mes clients. Ils adorent l'expérience d'achat fluide.",
    contactForm: [
      {
        label: 'Prénom',
        placeholder: 'Écrivez votre prénom',
      },
      {
        label: 'Nom',
        placeholder: 'Écrivez votre nom de famille',
      },
      {
        label: 'Email',
        placeholder: 'Écrivez votre adresse e-mail',
      },
      {
        label: 'Numéro de téléphone',
        type: 'phone',
        placeholder: '(555) 555-1234',
      },
    ],
    message: 'Message',
    send: 'Envoyer',
    footerText: `Chez Fintechracy, nous construisons le pont numérique
        qui relie les entreprises et les clients, en exploitant la technologie pour améliorer la communication et l'efficacité.`,
    otherLinks: [
      {
        link: 'FAQ',
        path: 'faq',
      },
      {
        link: 'Conditions générales',
        path: 'termsAndConditions',
      },
      {
        link: 'Politique de confidentialité',
        path: 'privacyPolicy',
      },
      {
        link: 'Demande de suppression de compte',
        path: 'user-deletion',
      },
    ],
    linksTitle: 'Nos Liens',
    otherLinksTitle: 'Autres Liens',
    rights: 'Tous droits réservés @Fintechracy',
    dowNow: 'Télécharger',
  },

  ar: {
    links: [
      {
        link: 'الرئيسية',
        path: '',
      },
      {
        link: 'شركة',
        path: 'ftr-business',
      },
      {
        link: 'زبون',
        path: 'ftr-client',
      },
      {
        link: 'متجر',
        path: 'ftr-store',
      },
      {
        link: 'المدونة',
        path: 'blogs',
      },
      {
        link: 'اتصل بنا',
        path: 'contactez-nous',
      },
    ],
    download: `تحميل`,
    contact: 'اتصلوا بنا',
    testimonialTitle1: 'شهادات عملائنا',
    testimonialTitle2: 'شهادات شركائنا',
    testimonialTitle3: '',
    testimonialText:
      'نظام نقاط البيع من Fintechracy قد غيَّر بشكل جذري عملي التجاري. إنه يُعد مثل وجود مساعد شخصي لعملائي. إنهم يقدرون بشدة هذه التجربة السلسة للتسوق.',
    testimonialText2:
      'نظام نقاط البيع من Fintechracy قد غيَّر بشكل جذري عملي التجاري. إنه يُعد مثل وجود مساعد شخصي لعملائي. إنهم يقدرون بشدة هذه التجربة السلسة للتسوق.',
    contactForm: [
      {
        label: 'الاسم',
        placeholder: '',
      },
      {
        label: 'اللقب',
        placeholder: '',
      },
      {
        label: 'البريد الإلكتروني',
        placeholder: '',
      },
      {
        type: 'phone',
        label: 'رقم الهاتف',
        placeholder: '',
      },
    ],
    message: 'الرسالة',
    send: 'إرسال',
    footerText: `في Fintechracy، نقوم ببناء الجسر الرقمي الذي يربط الشركات والعملاء،مستغلين التكنولوجيا لتحسين التواصل والكفاءة`,
    otherLinks: [
      {
        link: 'الأسئلة الشائعة',
        path: 'faq',
      },
      {
        link: 'الشروط والأحكام العامة',
        path: 'termsAndConditions',
      },
      {
        link: 'سياسة الخصوصية',
        path: 'privacyPolicy',
      },
      {
        link: 'طلب حذف الحساب',
        path: 'user-deletion',
      },
    ],
    linksTitle: 'روابطنا',
    otherLinksTitle: 'روابط أخرى',
    rights: 'جميع الحقوق محفوظة @Fintechracy',
    dowNow: 'تحميل',
  },
};

export default data;
