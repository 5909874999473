import data from './data'
import images from '../../constants/images'

export default function Landing({lang}) {
  return (
    <div className='text-center    container mx-auto px-10 ph:px-5  pt-0 md:pt-5  '>
        <div className='md:text-[50px] text-[27px] font-bold md:leading-[80px] leading-[38px]'>
          <div className='text-primary '>{data[lang].landing1} </div>
          <div className='text-sec'> {data[lang].landing2}</div>
        </div>
        <p className='md:w-2/3 w-[95%] text-text mx-auto md:leading-9 leading-7 md:text-[22.55px] text-[16px] my-6  mb-16 pc:mb-10'>{data[lang].landingp}</p>
        <img src={images.landing} alt="" className=' w-full md:w-[67%] mx-auto' />
    </div>
  )
}