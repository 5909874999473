import images from "../../constants/images"

const data = {
    "en": {
        "download": "Download",
        "landing1": "Together we will",
        "landing2": "End paper receipts",
        "landingp": "We put at your service our systems and apps, easy to use and integrate along with excellent customer support.Join us today in our joint mission of a world without paper receipts.",
        "swiperTitle1": "One App,",
        "swiperTitle2": "All Systems, All Hardwares",
        "swiperMinTitle1": `Our app is engineered for seamless integration 
                            with every system and hardware configuration.`,
        "swiperTitle3": "Use It",
        "swiperTitle4": "Anywhere, Anytime",
        "swiperMinTitle2": `With Fintechracy, accessibility knows no bounds`,
        "offerTitle1": "Our offering",
        "offerTitle2": "in Detail",
        "offerTitle3": "Work with us",
        "offerTitle4": "Partner",
        "offerTitle5": "with Fintechracy !",
        "offerText": `Are you a business looking to eliminate paper receipts?
You are tired of recurring costs of receipts and the printer that 
breaks every time?
Do you want to improve your image as an environmentally responsible business
Our solution helps you with that and more. It's designed to 
seamlessly integrate into your existing infrastructure.
Ready to transform your retail business? 
Contact us today or `,
        "seeMore": "See more Details",
        "contact" : "Contact us",
        "clientTitle1" : "Our",
        "clientTitle2" : "Client app",
        "clientText1" : `
                        Our app lets you leave paper behind, accessing your receipts anywhere. Also,
                        it allows you to store your loyalty cards, making it easier than ever to manage
                        your purchases and rewards all in one place.
                        `,
        "clientText2" : "Ready to simplify your shopping journey? ",
        "clientTitle3" : "FTR Store for small",
        "clientTitle4" : " Business Owners:",
        "clientText3" : `
                            For small stores that can’t afford a big point of sales system, it helps
                            them simplify product management, access a vast product database,  a fast
                            barcode reader, and gain real-time business insights. And eliminate paper
                            receipts. Elevate your business today with us.
                        `,
        "clientText4" : "Ready to Discover the FTR Store App ?",
        "benTitle1" : "The Benefits of Going",
        "benTitle2" : "Paperless for Shoppers",
        "benMinTitle1" : "Discover how going paperless as a shopper can revolutionize your interactions with retailers and the way you manage your purchases",
        "benTitle3" : "The Benefits of Going",
        "benTitle4" : "Paperless for Retailers",
        "benMinTitle2" : "Modernize your retail business, See how going paperless can streamline operations and enhance customer interactions in today's digital retail environment see more details",
        "benCards1" : [
            {
                img: images.benCard1,
                title: 'Digital Receipts',
                text: 'Digital receipts can really make your life easier. They will help you by giving you a clear record of all your purchases that will last forever.'
            },
            {
                img: images.benCard2,
                title: 'Digital Membership Wallet',
                text: 'Simplifying and digitizing membership cards can reduce clutter and make it easier for you to access their rewards and benefits.'
            },
            {
                img: images.benCard3,
                title: ' Track your finances',
                text: 'Our app provides you a user-friendly solution for tracking your expenses effortlessly and managing your budget easily.'
            },
        ],
        "benCards2" : [
            {
                img: images.benCard4,
                title: 'Receipt Cost-Savings',
                text: 'By going paperless, you will eliminate the need for paper, ink, and maintenance costs associated with printing. This streamlined approach translates to significant savings for your business.'
            },
            {
                img: images.benCard5,
                title: 'Elevates Customer Satisfaction',
                text: 'Digital receipts enable a personalized shopping experience as retailers leverage data to customize offers and recommendations, ultimately enhancing customer satisfaction and fostering loyalty.'
            },
            {
                img: images.benCard6,
                title: 'Environmental Contribution',
                text: 'Reducing paper usage showcases sustainability commitment and appeals to eco-conscious shoppers, bolstering both the environment and brand reputation.'
            },
        ],
        "blogTitle" : "Our Fintechracy",
        "blogTitle2" : "Blog",
        "bigBlogTitle" : "How Technology is Transforming Retail",
        "bigBlogDate" : "05 September 2023 By Chaouki",
        "blogCardTitle": 'How Technology is Transforming Retail',
        "blogCardDate": '05 September 2023 By Chaouki',
        "blogCardsImgs" : [
            images.blogCrad1 ,     
            images.blogCrad2 ,            
            images.blogCrad3 ,            
            images.blogCrad4 ,        
        ],
    },

    "fr": {
        "download": "Télécharger",
        "landing1": "Ensemble mettons fin aux",
        "landing2": "tickets de caisse papier",
        "landingp": "Nous mettons à votre service nos systèmes et applications faciles à utiliser et à intégrer, accompagnés d'un excellent service client. Rejoignez-nous aujourd'hui dans notre mission commune d'un monde sans tickets de caisse papier.",


        "swiperTitle1": "Une application pour",
        "swiperTitle2": "tous les systèmes et hardwares",
        "swiperMinTitle1": `Notre application est conçue pour une intégration simple avec tous les systèmes et configurations matérielles.`,
        "swiperTitle3": "Utilisez-la",
        "swiperTitle4": "n'importe où, n'importe quand",
        "swiperMinTitle2": `Avec Fintechracy, l'accessibilité ne connaît aucune limite`,
        "offerTitle1": "Notre offre",
        "offerTitle2": "en détail",
        "offerTitle3": "Travaillez avec nous",
        "offerTitle4": "Devenez partenaire",
        "offerTitle5": "de Fintechracy !",
        "offerText": `Êtes-vous une entreprise cherchant à éliminer les tickets papier ?
En avez-vous assez des coûts récurrents des tickets et de l'imprimante qui tombe en panne à chaque fois ?
Souhaitez-vous améliorer votre image en tant qu'entreprise écoresponsable ?
Notre solution vous aide à atteindre ces objectifs et bien plus encore. Elle est conçue pour s'intégrer parfaitement dans votre infrastructure existante.
Prêt à transformer votre entreprise de vente ?
        `,
        "seeMore": "Découvrez plus de détails",
        "contact" : "Contactez-nous",
        "clientTitle1" : "Notre application",
        "clientTitle2" : "client",
        "clientText1" : `
        Notre application vous permet de vous débarrasser des tickets de caisse papier, en vous donnant accès à vos tickets de n'importe quel endroit, tout en simplifiant la gestion de vos achats et de vos cartes de fidélité, le tout en un seul endroit.
                        `,
        "clientText2" : "Prêt à simplifier votre experience shopping ?",
        "clientTitle3" : "FTR Store",
        "clientTitle4" : " pour les petits commerces",
        "clientText3" : `
        Pour les petites commerces au budgets limité, notre solution simplifie la gestion des produits, offre un accès à une vaste base de données, intègre un lecteur de codes-barres rapide et fournit des données commerciales en temps réel.
                        `,
        "clientText4" : "Prêt à découvrir l'application FTR Store ?",
        "benTitle1" : "Les avantages d'opter pour le sans-papier",
        "benTitle2" : "pour les acheteurs",
        "benMinTitle1" : "Découvrez comment une solution sans papier peut révolutionner vos interactions avec les commerces de proximités et la manière dont vous gérez vos achats.",
        "benTitle3" : "Les avantages d'opter pour le sans-papier",
        "benTitle4" : "pour les commerçants",
        "benMinTitle2" : "Découvrez comment une solution sans-papier peut fluidifier les opérations et améliorer vos interactions avec vos clients",
        "benCards1" : [
            {
                img: images.benCard1,
                title: 'tickets de caisse numériques',
                text: "Les tickets numériques peuvent réellement simplifier votre vie. Ils vous aideront en vous fournissant un enregistrement clair de tous votre historique d’achats qui perdurera éternellement"
            },
            {
                img: images.benCard2,
                title: 'Portefeuille des cartes de fidelité',
                text: "Simplifier et numériser les cartes de fidélité peut réduire l'encombrement et faciliter l'accès à vos récompenses et avantages."
            },
            {
                img: images.benCard3,
                title: 'Suivez vos finances',
                text: "Notre application FTR Client vous offre une solution intuitive pour suivre vos dépenses et gérer votre budget en toute simplicité et sans effort."
            },
        ],
        "benCards2" : [
            {
                img: images.benCard4,
                title: 'Économies de coûts liées aux tickets papier',
                text: "En optant pour le sans-papier, vous éliminerez le besoin de papier, d'encre et les coûts de maintenance liés à l'impression."
            },
            {
                img: images.benCard5,
                title: 'Satisfaction des clients',
                text: "Les tickets de caisse numériques permettent une expérience d'achat personnalisée, car les détaillants exploitent les données pour cibler les offres et les recommandations, améliorant ainsi la satisfaction de leurs clients et favorisant leur fidélité."
            },
            {
                img: images.benCard6,
                title: 'Contribution environnementale',
                text: "La réduction de l'utilisation du papier montre l'engagement envers la protection de l'envirenement, renforçant ainsi l'image de la marque et attirant les acheteurs sensibles à l'environnement."
            },

        ],
        "blogTitle" : "Notre",
        "blogTitle2" : "blog",
        "bigBlogTitle" : "Comment la technologie transforme le commerce",
        "bigBlogDate" : "05 septembre 2023 Par Chaouki",
        "blogCardTitle": 'Comment la technologie transforme le commerce',
        "blogCardDate": '05 septembre 2023 Par Chaouki',
        "blogCardsImgs" : [
            images.blogCrad1 ,     
            images.blogCrad2 ,            
            images.blogCrad3 ,            
            images.blogCrad4 ,        
        ],
    },

    "ar": {
        "download": `تحميل`,
        "landing1": "معًا سنضع",
        "landing2": "نهاية للفواتير الورقية",
        "landingp": `نقدم لكم أنظمتنا وتطبيقاتنا ذات الاستخدام والتكامل السهل، مع خدمة زبائن متميزة
        انضموا إلينا اليوم في مهمتنا المشتركة نحو عالم بدون فواتير ورقية`,
        "swiperTitle1": "تطبيق واحد،",
        "swiperTitle2": "جميع الأنظمة، جميع الأجهزة",
        "swiperMinTitle1": `تم تصميم تطبيقنا بعناية لضمان التكامل السلس مع جميع الأنظمة و الأجهزة`,
        "swiperTitle3": "استخدمه",
        "swiperTitle4": "في أي مكان و في اي وقت",
        "swiperMinTitle2": `لا يوجد حدود مع Fintechracy`,
        "offerTitle1": "عروضنا",
        "offerTitle2": "بالتفصيل",
        "offerTitle3": "اعمل معنا",
        "offerTitle4": "كن شريكًا",
        "offerTitle5": "مع Fintechracy",
        "offerText": `هل تمتلك شركة وتبحث عن وسيلة للتخلص من الايصالات الورقية؟ 
هل تعبت من تكاليف الايصالات والطابعة التي تتعطل باستمرار؟
هل ترغب في تعزيز صورتك كشركة مساهمة في الحفاظ على البيئة؟
حلنا الخاص يساعدكم في ذلك وأكثر. تم تصميمه للتكامل بسلاسة مع بنية الأنظمة الحالية لديكم
اتصلوا بنا اليوم أو اطلعوا على المزيد من التفاصيل`,
        "seeMore": "اطلعوا على المزيد من التفاصيل",
        "contact" : "اتصلوا بنا",
        "clientTitle1" : "تطبيق العملاء",
        "clientTitle2" : "FTR Client",
        "clientText1" : `
        تطبيقنا يتيح لك التخلص التام من استخدام الفواتير الورقية و الوصول إليها من أي مكان، كما يتيح لك حفظ بطاقات الاشتراك الخاصة بك مما يسهل عليك إدارة مشترياتك ومكافآتك في مكان واحد بشكل أكثر يسرا وفعالية.
                        `,
        "clientText2" : "هل أنتم مستعدون لتسوق افضل ؟ ",
        "clientTitle3" : "تطبيقنا",
        "clientTitle4" : " لأصحاب الأعمال الصغيرة FTR Store",
        "clientText3" : `
        بالنسبة لأصحاب المتاجر الصغيرة الذين لا يمكنهم توفير أنظمة نقاط البيع الكبيرة، يساعدكم هذا التطبيق على تبسيط إدارة المنتجات، والوصول إلى قاعدة بيانات ضخمة من المنتجات، واستخدام قارئ باركود سريع، والحصول على تحليلات اعمالك ، مع القضاء على الفواتير الورقية 
                        `,
        "clientText4" : "هل أنتم مستعدون لاستكشاف تطبيقنا ؟ ",
        "benTitle1" : "فوائد اعتماد الفواتير الرقمية",
        "benTitle2" : "للمشترين",
        "benMinTitle1" : "اكتشف كيف يمكنك من خلال تطبيقنا تسهيل عمليات الشراء و ادارة مشترياتك",
        "benTitle3" : "فوائد اعتماد الفواتير الرقمية",
        "benTitle4" : "للتجار",
        "benMinTitle2" : "اكتشف كيف يمكنك من خلال تطبيقنا تحسين تفاعلك مع عملائك و تطوير عملك",
        "benCards1" : [
            {
                img: images.benCard1,
                title: 'فواتير رقمية',
                text: 'الفواتير الرقمية يمكن أن تبسط حياتك بشكل فعّال ستساعدك على الحفاظ على سجل واضح لجميع مشترياتك و تبقى موجودة إلى الأبد في التطبيق'
            },
            {
                img: images.benCard2,
                title: 'محفظة بطاقات الاشتراك',
                text: 'تحويل بطاقات الاشتراكاك الورقية  إلى نسخ رقمية يمكن أن يقلل من التكدس ويسهل لك الوصول إلى مكافآتك ومزاياك'
            },
            {
                img: images.benCard3,
                title: 'تتبع نفقاتكم بسهولة',
                text: ' تطبيقنا يمكنكم من تتبع نفقاتكم وإدارة ميزانيتكم بسهولة و بدون جهد'
            },
        ],
        "benCards2" : [
            {
                img: images.benCard4,
                title: 'توفير تكاليف متعلقة بالفواتير',
                text: 'الاعتماد على الفواتير الرقمية يقضي على الحاجة إلى الورق والحبر وتكاليف الصيانة المتعلقة بالطباعة'
            },
            {
                img: images.benCard5,
                title: 'تعزيز رضا العملاء',
                text: ' من خلال الاطلاع على البيانات و تحليلها يمكنك تُقديم  العروض والمكافات، مما يعزز رضا و ولاء العملاء'
            },
            {
                img: images.benCard6,
                title: 'المساهمة البيئية',
                text: 'تقليل استخدام الورق يظهر التزامًا بالمحافضة على البيئة، مما يعزز صورة العلامة التجارية ويجذب المشترين الذين يهتمون بالبيئة'
            },
        ],
        "blogTitle" : "",
        "blogTitle2" : "مدونتنا",
        "bigBlogTitle" : "كيف تقوم التكنولوجيا بتحويل القطاع التجاري",
        "bigBlogDate" : "05 سبتمبر 2023 بواسطة شوقي",
        "blogCardTitle": 'كيف تقوم التكنولوجيا بتحويل القطاع التجاري',
        "blogCardDate": '05 سبتمبر 2023 بواسطة شوقي',
        "blogCardsImgs" : [
            images.blogCrad1 ,     
            images.blogCrad2 ,            
            images.blogCrad3 ,            
            images.blogCrad4 ,        
        ],
    }
}

export default data