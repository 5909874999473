import { Link } from 'react-router-dom'
import images from '../../constants/images'
import data from './data'

export default function JoinApp({ lang }) {
  return (
    <div className=' py-20 px-16 bg-sec flexCenter gap-6 pc:flex-col pc:mb-14'>
        <div className=' items-center  md:gap-0 gap-4 scale-100 md:scale-[70%] flex  flex-col  md:flex-row justify-between w-full '>
          <p className='w-8/12 text-[40px] pc:text-xl pc:w-[90%]  pc:leading-[2.5] pc:text-center  font-medium leading-[1.4] text-white'>
          {data[lang].joinComm}
        </p>
        <div className='w-2/12 pc:w-6/12'>
            <Link to={'https://play.google.com/store/apps/details?id=net.appazing.easyftp&pcampaignid=web_share'} 
            className='  flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg mb-4'>
               <img src={images.playStore} className=' w-14 pc:w-10' alt="" />
                <span className='text-2xl pc:text-sm text-white font-bold'>Play Store</span>
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=net.appazing.easyftp&pcampaignid=web_share'} 
            className='   flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg'>
               <img src={images.appStore} className=' w-14 pc:w-10' alt="" />
               <span className='text-2xl pc:text-sm text-white font-bold'>App Store</span>
            </Link>
        </div>
        </div>
        
    </div>
  )
}