const data = {
    "en": {
        "download": "Download",
        "landing1": "Transform Your Retail Business ",
        "landing2": "with Digital Receipts",
        "landingp": "We put at your service our systems and apps, easy to use and integrate along with excellent customer support.Join us today in our joint mission of a world without paper receipts.",
        "landing3": "Future-Proof Your Retail, Our FTR Business package Fits",
        "landing4": "Every Business and Tech Environment",
        "landingp2": "Discover how our digital receipt solution is transforming the shopping journey.",
        "contact" : "Contact us",
        "secTitle1" : "Receipt",
        "secTitle2" : "Cost-Savings",
        "secTitle3" : "Track the revenues",
        "secTitle4" : "anywhere",
        "secTitle5" : "Elevate",
        "secTitle6" : "Customer Satisfaction",
        "secTitle7" : "Environmental",
        "secTitle8" : "Contribution",
        "secTitle9" : "Precision Targeting",
        "secTitle10" : "with Personalized Ads",
        "secTitle11" : "Enhance",
        "secTitle12" : "Cybersecurity",
        "secTitle13" : "Promote",
        "secTitle14" : "Safe Shopping",
        "secText1" : "By going paperless, you will immediately cut expenses associated with purchasing paper rolls and ink for receipts, as well as the maintenance and repair costs for receipt printers, ultimately leading to substantial cost savings for your business.",
        "secText2" : "Before the era of FTR Business, managing and tracking client expenses used to be a tedious task. Now, with our app, you have the power to check every expense incurred by your clients and take full control of your business finances with our tailored solution.",
        "secText3" : "Digital receipts enable a personalized shopping experience as retailers leverage data to customize offers and recommendations, ultimately enhancing customer satisfaction and fostering loyalty.",
        "secText4" : "Elevating your brand's image by eliminating paper receipts showcase your commitment to sustainability. This visible and eco-conscious gesture reduces paper waste, lowers your environmental footprint, and resonates positively with your clients.",
        "secText5" : "Our app allows you to tap into valuable shopper insights by analyzing their buying habits so you can send highly targeted advertisements to the right audience that resonate with individual preferences.",
        "secText6" : "In the digital age, security is crucial. Our app takes extra steps to make sure transactions are safe. We use robust protection methods to keep customer info safe and private. With us, retailers and customers can feel confident about transitioning to e-receipts, knowing their data is secure.",
        "secText7" : "Our app minimizes physical contact, reducing the risk of virus transmission through paper receipts, and they contain harmful chemicals like bisphenol-A (BPA) and bisphenol-S (BPS) linked to various health risks.",
        "contactSection" : [
            {
                title : "Contact us",
                text: "We are here to assist you and answer any questions you have. Reach out today and let's start working together."
            },
            {
                title : "Connect to our services",
                text: "Unlock the power of our services and solutions.  Integrate our technology into your business and stay ahead in today's fast-paced world."
            },
            {
                title : "Send e-receipts today",
                text: "Start e-receipting today and elevate the shopping experience for your customers while reducing costs."
            },
        ]
    },

    "fr": {
        "download": "Télécharger",
        "landing1": "Transformez votre business",
        "landing2": "grace aux tickets de caisse numériques",
        "landingp": "Nous mettons à votre service nos systèmes et applications faciles à utiliser et à intégrer, accompagnés d'un excellent service client. Rejoignez-nous aujourd'hui dans notre mission commune d'un monde sans tickets de caisse papier.",
        "landing3": "Assurez l'avenir de votre magasin, notre offre FTR Business",
        "landing4": "s'adapte à tous les types d'entreprises et environnements technologiques",
        "landingp2": "Découvrez comment notre solution de ticket numérique transforme l'expérience d'achat.",
        "contact" : "Contactez-nous",
        "secTitle1" : "Économisez les coûts",
        "secTitle2" : "liés aux tickets",
        "secTitle3" : "Suivez les dépenses",
        "secTitle4" : "où que vous soyez",
        "secTitle5" : "Augmentez",
        "secTitle6" : "la satisfaction de vos client",
        "secTitle7" : "Contribuez",
        "secTitle8" : "à l'environnement",
        "secTitle9" : "Ciblez avec précision grâce",
        "secTitle10" : "à des publicités personnalisées",
        "secTitle11" : "Améliorez",
        "secTitle12" : "la cybersécurité",
        "secTitle13" : "promouvez",
        "secTitle14" : "un shopping sécurisé",
        "secText1" : "En passant au sans-papier, vous réduirez immédiatement les coûts liés aux rouleaux de papier, à l'encre et à l'entretien des imprimantes de tickets, ce qui vous permettera de realiser d'importantes économies pour votre entreprise.",
        "secText2" : "Avant FTR Business, la gestion et le suivi des dépenses des clients étaient des tâches fastidieuses. Désormais, avec notre application, vous avez le pouvoir de vérifier chaque dépense engagée par vos clients et de prendre le contrôle total de vos finances.",
        "secText3" : "Les tickets de caisse numériques permettent une expérience d'achat personnalisée, car les magasins utilisent les données pour cibler les offres et les recommandations, ce qui améliore en fin de compte la satisfaction leurs clients et encourage leur fidélité.",
        "secText4" : "Améliorez l'image de votre marque en éliminant les tickets papier, démontrez ainsi votre engagement envers la durabilité. Ce geste visible et respectueux de l'environnement réduit les déchets de papier, diminue votre empreinte environnementale et suscite une réaction positive chez vos clients.",
        "secText5" : "Notre application vous permet d'accéder à des informations précieuses sur les acheteurs en analysant leurs habitudes d'achat. Ainsi, vous pouvez envoyer des publicités hautement ciblées à la bonne audience, qui correspondent aux préférences individuelles.",
        "secText6" : "Notre application prend toutes les précautions nécessaires pour garantir la sécurité des transactions. Nous utilisons des méthodes de protection robustes pour préserver la sécurité et la confidentialité des informations clients.",
        "secText7" : "Notre application réduit les contacts physiques, diminuant ainsi le risque de transmission de virus via les tickets en papier, car ils contiennent des produits chimiques nocifs tels que le bisphénol A (BPA) et le bisphénol S (BPS), associés à divers risques pour la santé.",
        "contactSection" : [
            {
                title : "Contactez-nous",
                text: "Nous sommes là pour vous aider et répondre à toutes vos questions. Contactez-nous dès aujourd'hui et commençons à travailler ensemble."
            },
            {
                title : "Connectez-vous à nos services",
                text: "Intégrez notre technologie dans votre entreprise et restez en avance dans le monde d'aujourd'hui qui est en constante évolution"
            },
            {
                title : "Envoyez des tickets numériques aujourd'hui",
                text: "Commencez à utiliserles tickets de caisse numériques dès aujourd'hui et améliorez l'expérience d'achat de vos clients tout en réduisant les coûts."
            },
        ]
    },

    "ar": {
        "download": `تحميل`,
        "landing1": "قم بتطوير عملك",
        "landing2": " باستخدام الفواتير الرقمية",
        "landingp": `نقدم لكم أنظمتنا وتطبيقاتنا ذات الاستخدام والتكامل السهل، مع خدمة زبائن متميزة
        انضموا إلينا اليوم في مهمتنا المشتركة نحو عالم بدون فواتير ورقية`,
        "landing3": "FTR Business يتناسب مع جميع",
        "landing4": " أنواع الشركات والبيئات التكنولوجية",
        "landingp2": "اكتشف كيف تحول الفواتير الرقمية تجربة التسوق",
        "contact" : "اتصلوا بنا",
        "secTitle1" : "توفيرات مالية",
        "secTitle2" : "متعلقة بتكاليف الفواتير",
        "secTitle3" : "تتبع الإيرادات",
        "secTitle4" : "من أي مكان",
        "secTitle5" : "زيادة",
        "secTitle6" : "رضا العملاء",
        "secTitle7" : "المساهمة",
        "secTitle8" : "البيئية",
        "secTitle9" : "الاستهداف الدقيق",
        "secTitle10" : "من خلال الإعلانات",
        "secTitle11" : "احمي",
        "secTitle12" : "بياناتك",
        "secTitle13" : "تعزيز تجربة",
        "secTitle14" : "تسوق آمنة",
        "secText1" : "باستخدامك للفواتير الرقمية، ستقلل فوراً من التكاليف المرتبطة بأوراق الطباعة والحبر وصيانة طابعات الفواتير، مما سيؤدي إلى تحقيق توفيرات هائلة لشركتك.",
        "secText2" : " يوفر تطبيقنا واجهة مستخدم سهلة الاستخدام تمكنك من متابعة أداء عملك بسهولة وفهم التغييرات في الإيرادات على مر الوقت. يتيح لك تحليل البيانات وتقارير الإيرادات الشاملة الوصول إلى رؤى قيمة تساعدك في اتخاذ قرارات أفضل وتحسين استراتيجيات عملك",
        "secText3" : "الفواتير الرقمية تمنح تجربة تسوق سلسة، حيث تستفيد من البيانات لتخصيص العروض والتوصيات، مما يعزز رضا و ولاء عملاءك .",
        "secText4" : "حسّن صورة علامتك التجارية عبر القضاء على الفواتير الورقية، مؤكدين تفانيكم في الاستدامة و المحافظة على البيئة مما سيثير ردود فعل إيجابية من قبل عملائك.",
        "secText5" : "تطبيقنا يمنحكم وصولاً إلى معلومات ذات قيمة حول العملاء من خلال تحليل عاداتهم في الشراء. وبهذه الطريقة، يمكنكم إرسال إعلانات مستهدفة بشكل كبير للجمهور المناسب.",
        "secText6" : "تطبيقنا يتخذ تدابير استثنائية لضمان أمان المعاملات. نحن نستخدم أساليب حماية قوية للحفاظ على أمان وسرية معلومات العملاء",
        "secText7" : "تطبيقنا يقلل من مخاطر انتقال الفيروسات عبر الفواتير الورقية، كما انها تحتوي على مواد كيميائية ضارة مثل البيسفينول أ (BPA) والبيسفينول س (BPS)، والتي تأدي الى العديد من الامراض.",
        "contactSection" : [
            {
                title : "اتصل بنا",
                text: "نحن هنا لمساعدتك والإجابة على جميع استفساراتك. اتصل بنا اليوم ولنبدأ العمل معًا"
            },
            {
                title : "قم بتحميل تطبيقنا",
                text: "قم بدمج تقنيتنا في شركتك وابقَ دائمًا في المقدمة"
            },
            {
                title : "قم بإرسال الفواتير الرقمية اليوم",
                text: "ابدأ في استخدام الفواتير الرقمية اليوم وساهم في تحسين تجربة عملائك مع تقليل التكاليف"
            },
        ]
    }
}

export default data