import data from './data'
import images from '../../constants/images'
import { Link } from 'react-router-dom'

export default function Landing({lang}) {
  return (
    <div className='text-center container mx-auto px-10  ph:px-5 pt-5'>
        <div className='md:text-[50px] text-[25px] font-bold md:leading-[80px] leading-[38px]'>
          <div className='text-primary'>{data[lang].landing1}</div>
          <div className='text-sec'>{data[lang].landing2}</div>
        </div>
        <p className='md:w-2/3 w-[95%] text-text mx-auto md:leading-9 leading-7 md:text-[22.55px] text-[16px]  my-6 mpc:mb-16'>{data[lang].landingp}</p>
        <img src={lang === 'fr' ? images.ftrClientFr : images.ftrClientEn} alt="" className='  mx-auto w-full md:w-[67%] mb-16' />
        <div className='flex justify-center gap-6'>
            <Link to={'https://play.google.com/store/apps/details?id=net.appazing.easyftp&pcampaignid=web_share'} className='flexBetween p-3 border-[0.75px] border-text rounded-lg gap-3'>
               <img src={images.playStore} className=' w-12 pc:w-10' alt="" />
                <span className='text-2xl pc:text-sm font-bold'>Play Store</span>
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=net.appazing.easyftp&pcampaignid=web_share'} className='flexBetween p-3 border-[0.75px] border-text rounded-lg gap-3'>
               <img src={images.appStore} className=' w-14 pc:w-10' alt="" />
               <span className='text-2xl pc:text-sm font-bold'>App Store</span>
            </Link>
        </div>
        <div className="mpc:w-[55%] w-4/5 mx-auto md:my-36 md:mt-44 my-10">
          <h1 className=' md:text-4xl text-2xl font-bold text-center text-primary'>
              {data[lang].landing3} <span className='text-sec'>{data[lang].landing4}</span>
          </h1>
          <p className='minTitle w-full'>{data[lang].landingp2} <span className="text-sec">{data[lang].seeMore}</span></p>
        </div>
    </div>
  )
}