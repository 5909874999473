import { Link } from "react-router-dom";
import images from "../constants/images";
import data from "./data";

export default function Footer({lang}) {
  return (
    <div className=" pt-10 md:pt-10 bg-[#F4F3FF]    ">
      <div className="flexBetween   items-start md:px-24 px-3 md:gap-3 justify-between scale-90  md:flex-row flex-col">
        <div className="md:w-[42%]  md:px-0 px-5 w-full md:text-start text-center">
            <img src={images.logo} className="  " alt="" />
            <p className=  {`w-full   ${
          lang === "ar" ? "  text-right " :'text-left'
        }    text-text minTitle  text-[16px] leading-8 md:text-[17px]`} >{data[lang].footerText}</p>
        </div>
        <div className=" pl-5  md:pl-16 w-full  md:w-[60%] gap-3  md:pt-0  pt-6 flex flex-row px-5 ">
           <div className=" w-full text-start  ">
          <h1 className="text-primary md:text-2xl text-xl mb-5 font-bold">{data[lang].linksTitle}</h1>
          <div>
            {data[lang].links.map( link => 
              <Link className="block mb-3 md:text-[15px] text-base text-text" to={`/${link.path}`} key={link.path}>
                {link.link}
              </Link>
            )}
          </div>
        </div>
        <div className="  w-full text-start  ">
          <h1 className="text-primary md:text-2xl text-xl mb-5 font-bold">{data[lang].otherLinksTitle}</h1>
          <div>
            {data[lang].otherLinks.map( link => 
              <Link className="block mb-3 md:text-[15px]  text-base text-text" to={`/${link.path}`} key={link.path}>
                {link.link}
              </Link>
            )}
          </div>
        </div>
        </div>
       
      </div>
      <div className="border-[1px] mt-3 text-text border-[#CCCAE9] p-3 text-center md:text-sm text-xs">
        {data[lang].rights}
      </div>
    </div>
  )
}