import images from '../../constants/images'
import data from './data'

export default function Greener({ lang }) {
  return (
    <div>
        <img src={images.ftrClientBI} className='md:mt-60 md:mb-40 mb-14 mt-20 w-full md:h-auto h-72' alt="" />
        <div className='container mx-auto px-10 ph:px-5 pt-5 pc:mb-14 mb-44'>
          <h1 className=' md:text-4xl text-2xl font-bold text-center text-primary md:mb-10 mb-6'>
            {data[lang].greenerTitle1} <span className='text-sec'>{data[lang].greenerTitle2}</span>
          </h1>
          <p className='minTitle w-2/3 pc:w-full pc:mb-10'>{data[lang].greenerText}</p>
          <h1 className='mpc:text-2xl text-primary text-[19px] text-center pc:w-[90%] mpc:mt-20 mpc:mb-16 mx-auto'>{data[lang].greenerMinTitle}</h1>
          <div className='flex justify-center gap-8 mt-7 scale-100  md:scale-[80%]'>
            {
              data[lang].greenerCards.map((i,index) => 
                <div key={index} className={`w-1/6 pc:w-[30%] text-center`}>
                   <div className={`${i.color} mpc:p-7 p-3 mpc:w-32 mpc:h-32 w-16 h-14 flexCenter rounded-2xl mx-auto`}>
                      <img src={i.img} className='w-[50%] ' alt="" />
                   </div>
                   <div className='mt-3   md:text-2xl text-xs text-primary'>
                    <p className='  font-medium  text-sm md:text-3xl '>{i.num}</p>
                    <p>{i.of}</p>
                   </div>
                </div>
              )
            }
          </div>
        </div>
    </div>
  )
}