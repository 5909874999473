import React from 'react'
import images from '../constants/images'

export default function ImgTextSection({bottomImage, right, title1, title2, text, img, lang }) {
    if (right) {
        return (
          <div className='mpc:mb-44  md:mb-5  mb-12 scale-100 md:-my-14  md:scale-[68%] '>
              <div className={`grid container mx-auto px-10 ph:px-5 pt-5 mpc:grid-cols-2 mpc:gap-14 gap-3 ${bottomImage ? 'items-between' : 'items-center'}`}>
                  <div className={`flex flex-col ${bottomImage ? 'justify-between' : 'mpc:gap-6 pc:gap-3'} pc:text-center`}>
                      <h1 className=' md:text-4xl text-2xl font-bold text-primary leading-10'>
                          <span className='text-sec'>{title1}</span> {title2}
                      </h1>
                      <p className='minTitle mpc:my-0 mb-7   text-center md:text-left      w-full mx-0 mpc:leading-[50px]'>{text}</p>
                      <div className='relative scale-100   md:scale-90'>
                        {bottomImage && <img src={images.x}
                         className={`absolute bg-[#FF6363] p-3 ${lang !== 'ar' ? 'right-8 pc:left-8' : 'left-8'} top-5 rounded-full w-[41px] h-[41px]`} alt="" />}
                        <img src={bottomImage} className={`mpc:w-80 w-full ${ lang !== 'ar' ? 'ml-auto' : 'mr-auto'}`} alt="" />
                        {bottomImage && lang !== 'ar' && <img src={images.ft} 
                        className={`mpc:absolute mpc:-right-16 mpc:-top-16 pc:rotate-180 pc:mx-auto`} alt="" />}
                        {bottomImage && lang === 'ar' && <img src={images.ft2} 
                        className={`mpc:absolute mpc:-left-12 mpc:-top-16 pc:rotate-180 pc:mx-auto`} alt="" />}
                      </div>
                  </div>
                    <div className={`relative  ${bottomImage && 'pc:h-[191.66px]'}`}>
                        { bottomImage && <img src={images.t} 
                        className={`absolute bg-[#90FF7D] mpc:p-4 p-3 top-5 rounded-full ${lang !== 'ar' ? 'right-8 pc:left-8' : 'left-8'} mpc:w-[73px] mpc:h-[73px] w-[41px] h-[41px]`} alt="" />}
                        <img src={img} className='w-full pc:object-cover pc:rounded-lg pc:h-full' alt="" />
                    </div>
              </div>
          </div>
        )
    } else {
        return (
          <div className='mpc:mb-44 mb-12 scale-100  md:scale-[68%] md:-my-14'>
              <div className={`grid container mx-auto px-10 ph:px-5 pt-5 mpc:grid-cols-2 mpc:gap-14 gap-3  ${bottomImage ? 'items-between' : 'items-center'} `}>
                  <div className={`relative ${bottomImage && 'pc:h-[191.66px]'} mpc:order-1 order-2`}>
                        { bottomImage && <img src={images.t} 
                        className={`absolute bg-[#90FF7D] mpc:p-4 p-3 left-8 top-5 rounded-full mpc:w-[73px] mpc:h-[73px] w-[41px] h-[41px] ${lang !== 'ar' ? 'left-8' : 'right-8'}`} alt="" />}
                        <img src={img} className='w-full pc:object-cover pc:rounded-lg pc:h-full' alt="" />
                    </div>
                  <div className={`order-1 flex flex-col pc:text-center ${bottomImage ? 'justify-between' : 'mpc:gap-6 pc:gap-2'}`}>
                      <h1 className=' md:text-4xl text-2xl font-bold text-primary'>
                          <span className='text-sec'>{title1}</span> {title2}
                      </h1>
                      <p className='minTitle mpc:my-0 mb-4 mpc:text-start w-full mx-0 mpc:leading-[50px]'>{text}</p>
                      <div className='relative'>
                        <img src={bottomImage} className={`mpc:w-80 w-full ${ lang !== 'ar' ? 'mr-auto' : 'ml-auto'}`} alt="" />
                        { bottomImage && <img src={images.x} 
                        className={`absolute bg-[#FF6363] p-3 left-8 top-5 rounded-full w-[41px] h-[41px] ${lang !== 'ar' ? 'left-8' : 'right-8'}`} alt="" />}
                        {bottomImage && lang !== 'ar' && <img src={images.ft2} 
                        className='mpc:absolute mpc:-left-12 mpc:-top-16 pc:rotate-[200deg] pc:mx-auto' alt="" />}
                        {bottomImage && lang === 'ar' && <img src={images.ft} 
                        className='mpc:absolute mpc:-right-16 mpc:-top-16 pc:rotate-[200deg] pc:mx-auto' alt="" />}
                      </div>
                  </div>
              </div>
          </div>
        )
        
    }
}
