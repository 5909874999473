import React, { useEffect, useRef, useState } from 'react'
import images from '../constants/images'
import data from './data'
import { Link } from 'react-router-dom'

export default function DownloadBtn({lang, nav, client, store}) {
    const [openDow, setOpenDow] = useState(false)
    const [openStoreLinks, setOpenStoreLinks] = useState(store ? true : false)
    const [openClientLinks, setOpenClientLinks] = useState(client ? true : false)  
    const dowRef = useRef(null);

    useEffect(() => {
      const closeDowOnOutsideClick = (event) => {
        if (openDow && dowRef.current && !dowRef.current.contains(event.target)) {
          setOpenDow(false);
        }
      };
  
      document.body.addEventListener('click', closeDowOnOutsideClick);
  
      return () => {
        document.body.removeEventListener('click', closeDowOnOutsideClick);
      };
    }, [openDow]);

  return (
    <div className='relative w-16 w-fit' ref={dowRef}>
          <button className={nav ? 'flexCenter gap-2 btn text-xl pc:text-xs pc:px-3' :
           'btn mpc:mx-0 px-5 mt-5 pc:px-6 pc:py-3 mx-auto md:text-[16px] text-xs'}
          onClick={()=>setOpenDow(!openDow)}>
            {nav ? data[lang].download : data[lang].dowNow}
            <img src={images.arrowbWhite} className='pc:text-[6px]' alt="" />
          </button>
        <div className={`absolute top-10 bg-[#EFEFEF] ${!client && !store ? 'w-[107%]' : 'w-full'} ${lang === 'ar' ? 'pc:left-0 mpc:w-[117%]' : 'pc:right-0 mpc:w-full'} rounded-md ${!openDow && 'hidden'}`}>
              { !client && <>
              {!store && <div onClick={()=> setOpenStoreLinks(!openStoreLinks)}
              className='text-primary cursor-pointer hover:font-bold flexBetween mpc:gap-4 gap-1 text-[15px] pc:text-[10px] mpc:px-3 px-2 py-2 transition-all'>
                <div className='flex items-center'>
                  <img src={images.ftrStoreLogo} className='pc:w-[27px]' alt="" />
                  <span>FTR Store</span>
                </div>
                <img src={images.dropdownfb} alt="" />
              </div>}
              <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.pos'}
               className={`text-primary ${openStoreLinks ? 'flex' :'hidden'} cursor-pointer hover:font-bold flexCenter gap-2 mpc:text-xs text-[7px] px-7 py-2 transition-all`}>
                  <img src={images.playStore} className='w-3' alt="" />
                  <span>Play Store</span>
              </Link>
              <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.pos'}
               className={`text-primary ${openStoreLinks ? 'flex' :'hidden'} cursor-pointer hover:font-bold flexCenter gap-2 mpc:text-xs text-[7px] px-7 py-2 transition-all`}>
                  <img src={images.appStore} className='w-3' alt="" />
                  <span>App Store</span>
              </Link> </>
              }
              { !store && <>
              {!client && <div onClick={()=> setOpenClientLinks(!openClientLinks)}
              className='text-primary cursor-pointer hover:font-bold flexBetween mpc:gap-4 gap-1 text-[15px] pc:text-[10px] mpc:px-3 px-2 py-2 transition-all'>
                <div className='flex items-center'>
                  <img src={images.ftrClientLogo} className='pc:w-[27px]' alt="" />
                  <span>FTR Client</span>
                </div>
                <img src={images.dropdownfb} alt="" />
              </div>
              }
              <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.user'}
               className={`text-primary ${openClientLinks ? 'flex' :'hidden'} cursor-pointer hover:font-bold flexCenter gap-2 mpc:text-xs text-[7px] px-7 py-2 transition-all`}>
                  <img src={images.playStore} className='w-3' alt="" />
                  <span>Play Store</span>
              </Link>
              <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.user'}
               className={`text-primary ${openClientLinks ? 'flex' :'hidden'} cursor-pointer hover:font-bold flexCenter gap-2 mpc:text-xs text-[7px] px-7 py-2 transition-all`}>
                  <img src={images.appStore} className='w-3' alt="" />
                  <span>App Store</span>
              </Link>
              </>}
          </div>
        </div>
  )
}
