import images from "../../constants/images"

const data = {
    "en": {
        "download": "Download",
        "landing1": "Welcome to the future of",
        "landing2": "shopping receipts!",
        "landingp": "Say goodbye to the hassle of paper receipts piling up in your wallet or cluttering your inboxes. Go paperless. Go FTR Client.",
        "landing3": "FTR Client:",
        "landing4": " Where paper receipts go extinct and our planet breathes a sigh of relief.",
        "landingp2": "",
        "contact" : "Contact us",
        "secTitle1" : "Digital Receipts",
        "secTitle2" : "at your fingertips",
        "secTitle3" : "Track your expenses",
        "secTitle4" : "anywhere",
        "secTitle5" : "Digital",
        "secTitle6" : "Membership Wallet",
        "secTitle7" : "Protect",
        "secTitle8" : "Your Privacy",
        "secTitle9" : "Enjoy",
        "secTitle10" : "Personalized Offers",
        "secTitle11" : "Say Goodbye to",
        "secTitle12" : "Faded Paper Receipts",
        "secText1" : "Say goodbye to the hassle of managing stacks of paper receipts and the risk of losing them. With our app, all your receipts are effortlessly organized and ready at your fingertips.",
        "secText2" : "Before the era of FTR Client, tracking expenses meant sifting through a pile of paper tickets and receipts. Now, with our app, you can enjoy the convenience of having all your shopping expenses neatly organized in one place. ",
        "secText3" : "Say goodbye to clutter and hello to convenience. Our app simplifies and digitizes your membership cards, ensuring all your cards are easily accessible in one place, right at your fingertips. ",
        "secText4" : "With digital receipts, We ensure that only you can access your purchase information with digital receipts. Your data is securely stored within our app, ensuring your purchase information remains confidential and accessible for as long as needed.",
        "secText5" : "Experience the delight of receiving exclusive offers and tailored recommendations from your preferred stores, enhancing your shopping experience.",
        "secText6" : "Paper receipts often fade, making past purchases hard to track. With our app, your receipt data remains accessible for years, offering a clear and enduring record of transactions.",
        "greenerTitle1" : "Join the Movement for a",
        "greenerTitle2" : "Greener Planet:",
        "greenerText" : "Did you know that paper receipts come at a high cost to our environment? Join the sustainability movement by eliminating paper receipts, reducing millions of pounds of paper waste annually. By doing so, you become a vital part of ensuring a greener planet for future generations.",
        "greenerMinTitle" : "In the last year paper receipts have cost our planet",
        "greenerCards" : [
            {
                img : images.tree,
                num: "3 million",
                of: "trees ",
                color : 'bg-[#2fe900]'
            },
            {
                img : images.water,
                num: "9 billion",
                of: "gallons of water",
                color : 'bg-[#00c2ff]'
            },
            {
                img : images.co2,
                num: "4 billion",
                of: "pounds of CO2",
                color : 'bg-[#ca34ff]'
            },
        ],
        "joinComm" :"Ready to join us in making a difference too? Download now to embrace a paperless, eco-conscious lifestyle and help create a positive impact",
    },

    "fr": {
        "download": "Télécharger",
        "landing1": "Bienvenue au",
        "landing2": "ticket de caisse numérique",
        "landingp": "Dites adieu à la corvée des tickets qui s'accumulent dans votre portefeuille Passez au sans-papier, Rejoignez FTR Client.",
        "landing3": "FTR Client :",
        "landing4": "Où les tickets papier disparaissent et notre planète est soulagée",
        "landingp2": "",
        "contact" : "Contactez-nous",
        "secTitle1" : "Accédez facilement",
        "secTitle2" : "à vos tickets numériques.",
        "secTitle3" : "Suivez vos dépenses",
        "secTitle4" : "où que vous soyez",
        "secTitle5" : "Portefeuille",
        "secTitle6" : "des cartes de fidelité",
        "secTitle7" : "Sécurisez",
        "secTitle8" : "vos données",
        "secTitle9" : "Profitez",
        "secTitle10" : "d'offres personnalisées",
        "secTitle11" : "Dites adieu",
        "secTitle12" : "aux tickets qui s'effacent",
        "secText1" : "Dites adieu à la corvée de la gestion de piles de tickets en papier et et le risque de les perdre.Avec notre application, tous vos tickets sont organisés sans effort et prêts à être consultés facilement.",
        "secText2" : "Avant FTR Client, suivre les dépenses signifiait trier des piles de tickets de caisse en papier. Désormais, avec notre application, vous pouvez profiter de la commodité d'avoir toutes vos dépenses d'achat soigneusement organisées à un seul endroit.",
        "secText3" : "Notre application simplifie et numérise vos cartes de fidélité, garantissant que toutes vos cartes soient facilement accessibles à un seul endroit.",
        "secText4" : "Avec les tickets de caisse numériques, vos données sont stockées en toute sécurité dans notre application, garantissant que vos informations d'achat restent confidentielles et accessibles aussi longtemps que nécessaire.",
        "secText5" : "Recevez des offres exclusives et des recommandations personnalisées de vos boutiques préférées, enrichissant ainsi votre expérience de shopping.",
        "secText6" : "les tickets en papier perdent leur lisibilité avec le temps, ce qui complique le suivi des précédents achats. Notre application assure la conservation de vos données de tickets sur plusieurs années.",
        "greenerTitle1" : `Rejoignez le mouvement`,
        "greenerTitle2" : `pour une planète verte`,
        "greenerText" : `Êtes-vous conscient que les tickets en papier ont un impact environnemental considérable ?
        Rejoignez le mouvement pour la durabilité en éliminant les tickets en papier, contribuant ainsi à la réduction de millions de tonnes de déchets papier chaque année. En agissant ainsi, vous devenez un acteur essentiel pour garantir une planète verte pour les futures générations.`,
        "greenerMinTitle" : `Au cours de la dernière année, les tickets ont eu un impact sur notre planète:`,
        "greenerCards" : [
            {
                img : images.tree,
                num: "3 millions",
                of: "Arbres",
                color : 'bg-[#2fe900]'
            },
            {
                img : images.water,
                num: "34.07 billion",
                of: "liters d’eau",
                color : 'bg-[#00c2ff]'
            },
            {
                img : images.co2,
                num: "4 milliards",
                of: "Livres de CO2",
                color : 'bg-[#ca34ff]'
            },
        ],
        "joinComm" :"Prêt à vous engager pour un impact positif ? Téléchargez dès maintenant pour adopter un mode de vie éco-responsable sans papier.",    
    },

    "ar": {
        "download": `تحميل`,
        "landing1": "تخلص من الفواتير المتراكمة معنا",
        "landing2": "",
        "landingp": `الوداع للفواتير الورقية المتراكمة في محفظتكم. انضموا إلى عصر الفواتير الرقمية مع تطبيقنا`,
        "landing3": "FTR Client :",
        "landing4": "حيث تصبح الفواتير الورقية شيئًا من الماضي",
        "landingp2": "",
        "contact" : "اتصلوا بنا",
        "secTitle1" : "الاطلاع بسهولة على فواتيرك.",
        "secTitle2" : "",
        "secTitle3" : "تتبع مصاريفك في أي مكان",
        "secTitle4" : "",
        "secTitle5" : "محفظة بطاقات الاشتراك",
        "secTitle6" : "",
        "secTitle7" : "احمي بياناتك",
        "secTitle8" : "",
        "secTitle9" : "استفد من عروض مخصصة",
        "secTitle10" : "",
        "secTitle11" : "قولوا وداعًا للتذاكر المتلاشية",
        "secTitle12" : "",
        "secText1" : "قولوا وداعًا لعناء البحث عن الفواتير الورقية المتراكمة في محفظتكم. مع تطبيقنا، يتم تنظيم جميع فواتيرك بسهولة وجاهزة للاطلاع عليها بسهولة اينما كمنتم.",
        "secText2" : "كان تتبع المصروفات يعني البحث في كومة من الفواتير الورقية. الآن، مع تطبيقنا، يمكنك الاستفادة من سهولة العثور على جميع مصروفاتك بعناية مُنظمة في مكان واحد",
        "secText3" : "تطبيقنا يُبسط ويُرقم بطاقات الاشتراك الخاصة بك، مع ضمان أن جميع بطاقاتك سهلة الوصول إليها في مكان واحد",
        "secText4" : " يتم تخزين بياناتك بأمان في تطبيقنا، مع ضمان بقاء معلوماتك الشرائية سرية وقابلة للوصول اليها طوال الوقت .",
        "secText5" : "بفضل استخدام تطبيقنا، ستتاح لك فرصة الحصول على عروض حصرية وتوصيات مخصصة تمامًا وفقًا لاهتماماتك واختياراتك الشخصية من متاجرك المفضلة. سيساعدك ذلك على تعزيز تجربتك في التسوق بشكل استثنائي، حيث ستصل إلى أفضل الصفقات والمنتجات التي تناسب ذوقك.",
        "secText6" : "تفقد الفواتير الورقية وضوحها مع مرور الوقت، مما يجعل من الصعب تتبع المشتريات السابقة. تطبيقنا يضمن الاحتفاظ ببيانات الفواتير الخاصة بك على مر السنين",
        "greenerTitle1" : "انضموا إلينا من أجل الحفاظ على كوكبنا",
        "greenerTitle2" : "",
        "greenerText" : `هل تدركون أن الفواتير الورقية تؤثر بشكل كبير على البيئة؟
        انضموا إلينا من أجل المحافضة على البيئة بالتخلص من الفواتير الورقية، مساهمين بذلك في تقليل الملايين من أطنان النفايات الورقية سنويًا. من خلال التصرف بهذه الطريقة، تصبحون جزءًا أساسيًا في الحفاظ على كوكبنا للأجيال القادمة.`,
        "greenerMinTitle" : "خلال العام الماضي، كان للفواتير تأثير على كوكبنا :",
        "greenerCards" : [
            {
                img : images.tree,
                num: "3 ملايين",
                of: "شجرة",
                color : 'bg-[#2fe900]'
            },
            {
                img : images.water,
                num: "34.07 مليار",
                of: "لتر من الماء",
                color : 'bg-[#00c2ff]'
            },
            {
                img : images.co2,
                num: "4 مليارات",
                of: "ثاني أكسيد الكربون",
                color : 'bg-[#ca34ff]'
            },
        ],
        "joinComm" :"انضم إلينا اليوم وكن جزءًا من تغيير إيجابي يؤثر على بيئتنا ومجتمعنا",
    }
}

export default data