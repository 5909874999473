const data = {
    "en": {
        "download": "Download",
        "landing1": "Discover Our Powerful ",
        "landing2": "FTR Store App",
        "landingp": "For small stores owners, who can’t afford big point of sales systems. With our free point of sales app we give them equal access to new retail technologies",
        "landing3": "Why should you choose our",
        "landing4": "FTR Store app",
        "landingp2": "Selecting the right POS app for your business is a critical decision. At Fintechracy, we believe our FTR Store app stands out for several compelling reasons.",
        "contact" : "Contact us",
        "secTitle1" : "Instant",
        "secTitle2" : "Start",
        "secTitle3" : "Real-time Business",
        "secTitle4" : "Insights",
        "secTitle5" : "Fast Barcode",
        "secTitle6" : "Reader",
        "secTitle7" : "Vast",
        "secTitle8" : "Product Database",
        "secTitle9" : "Product",
        "secTitle10" : "Management",
        "secTitle11" : "Receipt",
        "secTitle12" : "Delivery",
        "secText1" : "Simple, download the app easily and start working immediately without any setup or hassle.",
        "secText2" : "Get Instant Valuable Business Insights with Real-Time Statistics and Data Analysis.",
        "secText3" : "Our app includes a highly efficient scanner for seamless operations and enhanced efficiency in various environments.",
        "secText4" : "Access a database of 400 million products maintained by our community, eliminating the need for manual product entry.",
        "secText5" : "Effortlessly manage your products, update pricing, and maintain control over your inventory, right at your fingertips.",
        "secText6" : "Send receipts easily via the FTR Store app to enhance customer engagement.",
        "BusinessTitle1" : "Perfect for",
        "BusinessTitle2" : "Every Business Type",
        "BusinessText" : "Our app is thoughtfully designed for small businesses whether you run a restaurant, coffee shop, fast-food establishment, bakery, fashion boutique, grocery store, beauty salon, electronics shop, or home decor store, our solution is customized to meet the distinct needs of small businesses. We are dedicated to empowering them across various industries with the necessary tools for success. If you're a small business owner, our app is here to help you.",
        "joinComm" :"Looking to elevate your business? Take the first step and download our app today!",
    },

    "fr": {
        "download": "Télécharger",
        "landing1": "Découvrez notre puissante",
        "landing2": "application FTR Store.",
        "landingp": "Pour les boutique qui ne peuvent pas se permettre de gros systèmes de point de vente. Avec notre application gratuite de point de vente, nous leur offrons un accès égal aux nouvelles technologies de vente au détail.",
        "landing3": "Pourquoi devriez-vous choisir",
        "landing4": "notre application FTR Store",
        "landingp2": `Choisir la bonne application de point de vente pour votre boutique
        est une décision cruciale. Chez Fintechracy, nous sommes convaincus 
       que FTR Store se distingue pour plusieurs raisons convaincantes`,
        "contact" : "Contactez-nous",
        "secTitle1" : "Démarrage",
        "secTitle2" : "instantané",
        "secTitle3" : "Analyses en temps réel",
        "secTitle4" : "pour votre boutique",
        "secTitle5" : "Lecteur de",
        "secTitle6" : "codes-barres rapide",
        "secTitle7" : "Grande base",
        "secTitle8" : "de données de produits",
        "secTitle9" : "Gestion de produits",
        "secTitle10" : "",
        "secTitle11" : "",
        "secTitle12" : "Livraison de tickets",
        "secText1" : "Simple, téléchargez facilement l'application et commencez à travailler immédiatement sans aucune installation ni complication.",
        "secText2" : "Accédez à des informations stratégiques pour votre boutique en temps réel grâce à des statistiques instantanées et une analyse de données .",
        "secText3" : "Notre application est équipée d'un scanner de codes-barres ultra-performant, assurant des opérations fluides et une productivité améliorée dans divers contextes.",
        "secText4" : "Accédez à une base de données de 400 millions de produits entretenue par notre communauté, éliminant ainsi le besoin de saisie manuelle des produits.",
        "secText5" : "Gérez vos produits sans effort, mettez à jour les prix et maintenez le contrôle de votre inventaire.",
        "secText6" : "Envoyez facilement des tickets via l'application Fintechracy pour améliorer l'engagement client.",
        "BusinessTitle1" : "Parfait pour",
        "BusinessTitle2" : "tous les types de business",
        "BusinessText" : "Notre application est soigneusement conçue pour les petites businesses, que vous dirigiez un restaurant, un café, un fast-food, une boulangerie, une boutique de mode, une épicerie, un salon de beauté, un magasin d'électronique ou un magasin de décoration intérieure, notre solution est personnalisée pour répondre aux besoins spécifiques des petites businesses. Nous sommes déterminés à les aider à réussir dans divers secteurs d'activité en leur fournissant les outils nécessaires. Si vous êtes propriétaire d'une petite business, notre application est là pour vous aider.",
        "joinComm" :"Prêt à donner un coup de pouce à votre boutique ? Commencez dès maintenant en téléchargeant notre application",    
    },

    "ar": {
        "download": `تحميل`,
        "landing1": "رفيقك في تسيير",
        "landing2": "مبيعات متجرك اليومية",
        "landingp": `للمحلات التجارية التي لا تستطيع توفير أنظمة نقاط البيع الكبيرة، نقدم لها فرصة متساوية
        عن طريق تحويل هاتفهم الى نقطة بيع الكترونية من خلال تطبيقنا`,
        "landing3": "لماذا يجب عليكم اختيار تطبيقنا FTR Store",
        "landing4": "",
        "landingp2": `اختيار التطبيق المناسب لنقاط البيع في محلك التجاري هو قرار حاسم.
        نحن في شركة Fintechracy واثقون من أن تطبيق FTR Store يتفرّد بعدة أسباب مقنعة`,
        "contact" : "اتصلوا بنا",
        "secTitle1" : "ابدأ العمل فورا",
        "secTitle2" : "",
        "secTitle3" : "تحليلات فورية لمتجرك",
        "secTitle4" : "",
        "secTitle5" : "قارئ الباركود السريع",
        "secTitle6" : "",
        "secTitle7" : "قاعدة بيانات كبيرة للمنتجات",
        "secTitle8" : "",
        "secTitle9" : "إدارة المنتجات",
        "secTitle10" : "",
        "secTitle11" : "ارسال الفواتير",
        "secTitle12" : "",
        "secText1" : " قم بتنزيل التطبيق بسهولة وابدأ العمل على الفور بدون أي تعقيد",
        "secText2" : `بواسطة تطبيقنا، يمكنك تتبع الإيرادات بدقة وسهولة
        وهذا سيمكنك من تحسين مخزونك وخطط تسويقك بناءً على البيانات الدقيقة. تطبيقنا سيساهم بشكل كبير في تحقيق النجاح لمشروعك أو متجرك.`,
        "secText3" : "يمتاز تطبيقنا بتوفير ماسح باركود عالي الأداء، مما يؤمن سير عمل سلس وزيادة في الإنتاجية في مختلف السياقات.",
        "secText4" : "ستستفيد من وصولك إلى قاعدة بيانات تضم 400 مليون منتج، تم إدارتها وتحديثها من قبل مستخدمي التطبيق، وذلك للتخلص من ضرورة إدخال المنتجات يدويًا",
        "secText5" : "قم بإدارة منتجاتك بسهولة، قم بتحديث الأسعار و حافظ على تحكمك في منتجاتك بكفاءة.",
        "secText6" : "قم بإرسال الفواتير بسهولة عبر تطبيقنا لتعزيز الثقة مع عملائك.",
        "BusinessTitle1" : "مناسب بشكل ممتاز",
        "BusinessTitle2" : "لجميع أنواع الأعمال",
        "BusinessText" : "تم تصميم تطبيقنا بعناية فائقة لخدمة الأعمال الصغيرة، سواء كنت تدير مطعم، مقهى، وجبات سريعة، مخبزة، محل أزياء، بقالة، صالون تجميل، متجر إلكترونيات، أو محل ديكور داخلي، فإن تطبيقنا مصمم خصيصًا لتلبية احتياجات المحلات الصغيرة. نحن ملتزمون بمساعدكم على تحقيق النجاح في تجارتكم عبر توفير الأدوات اللازمة. إذا كنت مالكًا لمتجر او عمل ، تطبيقنا هنا لمساعدتكم",
        "joinComm" :"هل تتطلع إلى تطوير عملك؟ اخطوا الخطوة الأولى وقم بتنزيل تطبيقنا اليوم",
    }
}

export default data