import images from "../../constants/images";
import data from "./data";

export default function ContactSection({lang}) {
  return (
    <div className="mpc:py-16  py-7 bg-[#594EF10F] mpc:mt-72 pc:mt-20">
        <div className="container scale-100   md:scale-[70%] mx-auto px-10 ph:px-5 pt-5 grid mpc:grid-cols-2">
            <div className="relative pc:order-2">
                <img src={images.mobile} className="h-full w-full object-cover z-40 relative" alt="" />
            </div>
            <div className="pc:order-1">
            {data[lang].contactSection.map((i,index) => 
                <div key={i.title}>
                    <div className="flex items-center pc:text-center pc:flex-col gap-5 text-2xl font-bold text-primary">
                        <span className=" w-24 h-24 rounded-full bg-sec flexCenter text-white">{index + 1}</span>
                        <span>{i.title}</span>
                    </div>
                    <p className={`mpc:text-start text-center text-text mpc:text-2xl text-[19px]  leading-9 md:leading-[50px] w-[80%] pc:w-[90%] pc:mx-auto m-0 mb-4 pc:mt-4 ${ lang !== 'ar' ? 'mpc:ml-28' : 'mpc:mr-28'}`}>{i.text}</p>
                </div>
            )}
            </div>
        </div>
        <a href="#contact" className="btn mx-auto rounded-xl">{data[lang].contact}</a>
    </div>
  )
}
