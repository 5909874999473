import React from 'react'

export default function BenCard({item}) {
  return (
    <div className='text-center bg-white rounded-2xl px-4 py-6 mpc:w-[35%] w-full mx-auto justify-between boxShadow2'>
        <img src={item.img} alt="" className='w-14 mx-auto' />
        <h1 className=' mt-6  mb-1 md:mt-8 text-primary font-bold md:text-[22px] text-[19px]'>{item.title}</h1>
        <p className='text-text  leading-7 md:leading-8 md:text-[1.1rem] text-[14px]'>{item.text}</p>
    </div>
  )
}