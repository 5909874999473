import React from 'react'
import data from './data'
import images from '../../constants/images'

export default function Blog({lang}) {
  return (
    <div className='container mx-auto ph:px-5 scale-100   md:-mt-20  md:scale-[70%] pt-5 px-10'>
        <h1 className=' md:text-4xl text-2xl font-bold text-primary text-center md:my-44 my-16'>
            {data[lang].blogTitle} <span className="text-sec">{data[lang].blogTitle2}</span>
        </h1>
        <div className='gap-8 grid md:grid-cols-2 mt-16'>
            <div className='relative w-full'>
                <img src={images.bigBlog} className='w-full h-full mx-auto' alt="" />
                <div className={`absolute bottom-6 ${lang === 'ar' ? 'right-5' : 'left-5'} z-40`}>
                    <p className='text-white font-bold md:text-[23px] text-[15px]'>{data[lang].bigBlogTitle}</p>
                    <p className='text-[#BDBDBD] md:text-[20px] text-[13px] mt-3'>{data[lang].bigBlogDate}</p>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-6'>
                {
                    data[lang].blogCardsImgs.map(i=>
                        <div key={i} className='w-full'>
                            <img src={i} alt="" className=' w-full   ' />
                            <p className='my-3 text-primary font-bold md:text-xl text-xs leading-7'>{data[lang].blogCardTitle}</p>
                            <p className='text-text md:text-sm text-[10px]'>{data[lang].blogCardDate}</p>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}
