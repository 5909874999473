import { React, useEffect } from "react";
 

function Massege({ msg,   setMsg }) {
  useEffect(() => {
    if (msg !== "") {
      const timer = setTimeout(() => {
        setMsg("");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [msg]);

  return (
    <div
      className={` transform  z-50          md:left-10  flex flex-row h-16      left-[50%]  md:translate-x-0  -translate-x-1/2  md:translate-y-0 -translate-y-1/2   border-solid text-sm   px-5   rounded-xl shadow-xl fixed  ${
        msg == "" ? " opacity-0 -z-10 md:top-[105%] -top-12 " : " opacity-100 z-40 md:top-[85%] top-12"
      }  left-5 w-[86%]   sm:w-[270px] bg-green-500  duration-100  items-center gap-3 justify-start px-1 `}
    >
       
      <div className=" flex items-center justify-between text-center px-5   text-white  w-full">
        
      Your message has been sent successfully! Thank you!
      </div>
    </div>
  );
}

export default Massege;
