import React, { useEffect } from 'react'
import Landing from './Landing'
import ImgTextSection from '../../components/ImgTextSection'
import data from './data'
import images from '../../constants/images'
import Business from './Business'
import Testimonail from '../../components/Testimonail'
import Contact from '../../components/Contact'
import JoinApp from './JoinApp'

export default function FTRStore({lang}) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
        <Landing lang={lang} />  
        <ImgTextSection 
          title1={data[lang].secTitle1} 
          title2={data[lang].secTitle2}
          text={data[lang].secText1}
          img={images.ftrStore1}
          bottomImage={''}
          lang={lang}
        /> 
        <ImgTextSection 
          title1={data[lang].secTitle3} 
            title2={data[lang].secTitle4}
            text={data[lang].secText2}
            img={images.ftrStore2}
            bottomImage={''}
            lang={lang}
            right
        /> 
        <ImgTextSection 
          title1={data[lang].secTitle5} 
          title2={data[lang].secTitle6}
          text={data[lang].secText3}
          img={images.ftrStore3}
          bottomImage={''}
          lang={lang}
        /> 
        <ImgTextSection 
          title1={data[lang].secTitle7} 
            title2={data[lang].secTitle8}
            text={data[lang].secText4}
            img={images.ftrStore4}
            bottomImage={''}
            lang={lang}
            right
        /> 
        <ImgTextSection 
          title1={data[lang].secTitle9} 
          title2={data[lang].secTitle10}
          text={data[lang].secText5}
          img={images.ftrStore5}
          bottomImage={''}
          lang={lang}
        /> 
        <ImgTextSection 
          title1={data[lang].secTitle11} 
            title2={data[lang].secTitle12}
            text={data[lang].secText6}
            img={images.ftrStore6}
            bottomImage={''}
            lang={lang}
            right
        /> 
        <Business lang={lang} /> 
        <Testimonail client text2 lang={lang} /> 
        <JoinApp lang={lang} /> 
        <Contact lang={lang} /> 
    </>
  )
}
