import data from './data'
import images from '../../constants/images'
import { Link } from 'react-router-dom'

export default function Blogs({lang}) {
    const blogImages = [
        images.blog1,
        images.blog2,
        images.blog3,
        images.blog4,
        images.blog5,
        images.blog6,
        images.blog7,
        images.blog8,
        images.blog9,
        images.blog10,
        images.blog11,
        images.blog12,
    ]
  return (
    <div className=' scale-100 my-0 md:-my-[50%]  md:scale-75'>
        <div className='md:text-5xl text-center px-5 md:px-10 text-2xl font-bold md:leading-[80px] pt-5 leading-[38px]'>
          <span className='text-primary'>{data[lang].title1}</span>
          <span className='text-sec'>{data[lang].title2}</span>
        </div>
        <p className='md:w-3/5 w-[80%] text-center  text-text mx-auto md:leading-9 leading-7 md:text-[22.55px] text-[16px]  my-6'>{data[lang].textTitle}</p>
        <div className="mt-20 mpc:mb-28 mb-16 grid md:grid-cols-2 gap-16 md:w-10/12 w-[90%] mx-auto">
            {
                blogImages.map( (blog,index) => (
                    <Link to={`/blogDetails/${index+1}`} key={index} className=' mpc:mb-11 mb-3'>
                        <p className='bg-[#E5E3FF] md:hidden text-sm text-gray-500 w-fit py-4 px-6 rounded-full mx-auto mb-6'>{index % 2 === 0 ? data[lang].blogTypeStore : data[lang].blogTypeClient}</p>
                        <img className=' w-full md:w-[90%] ' src={blog} alt="" />
                        <h1 className='text-primary md:text-[28px] text-[20px] md:text-start text-center font-bold my-6'>{data[lang].blogTitle}</h1>
                        <div className='items-center gap-3 mb-6 md:flex hidden'>
                            <div className='bg-sec h-1 w-20'></div>
                            <p className='bg-[#E5E3FF] text-xl text-gray-500 w-fit py-4 px-6 rounded-full'>{index % 2 === 0 ? data[lang].blogTypeStore : data[lang].blogTypeClient}</p>
                        </div>
                        <p className= '  md:text-[20px] text-[16px] text-text md:text-start text-center'>{data[lang].blogText}</p>
                    </Link>
                ))
            }
        </div>
    </div>
  )
}
