import React, { useState } from 'react'
import data from './data'
import images from '../constants/images'
import {AiFillRightCircle, AiOutlineLeftCircle, AiOutlineRightCircle, AiFillLeftCircle} from 'react-icons/ai'

export default function Testimonail({ lang,client,text2 }) {
    const [index, setIndex] = useState(1)
    const inc = () => {
        if (index !== 3) {
            setIndex(index + 1)
        } else {
            setIndex(1)
        }
    }
    const dec = () => {
        if (index !== 1) {
            setIndex(index - 1)
        } else {
            setIndex(3)
        }
    }
  return (
    <div className='grid md:-my-20 my-16 mpc:grid-cols-2 items-center container mx-auto px-10  scale-100    md:scale-[70%]  ph:px-5 pt-5'>
        <h1 className='w-full text-[25px] text-center mpc:hidden font-bold text-primary'>
            {client ? data[lang].testimonialTitle1 : data[lang].testimonialTitle2} <span className="text-sec">{data[lang].testimonialTitle3}</span>
        </h1>
        <div className='pc:order-2'>
            <div className={`w-full ${index !== 1 && 'hidden'}`}>
                <h1 className='w-full text-[35px] font-bold pc:hidden text-primary'>
                    {client ? data[lang].testimonialTitle1 : data[lang].testimonialTitle2} <span className="text-sec">{data[lang].testimonialTitle3}</span>
                </h1>
                <p className='minTitle    text-left   mpc:text-start w-full md:mx-0 mb-10'>{text2 ? data[lang].testimonialText2 : data[lang].testimonialText}</p>
                <div className='flexCenter gap-4 justify-start pc:mx-6'>
                    <img src={images.testimonial} alt="" className=' mpc:w-[90px] w-14' />
                    <div className='flexBetween w-full'>
                        <div>
                            <p className='font-bold mpc:text-xl text-xs'>Chaouki</p>
                            <div className='flexCenter gap-2 mt-2'>
                                {[1,2,3,4,5].map( e => <img key={e} src={images.starYellow} className='mpc:w-5 w-3' alt='' />)}
                            </div>
                        </div>
                        <div className='flexCenter gap-6'>
                        {
                            lang === 'ar' ?
                            <>
                                <AiOutlineRightCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillLeftCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                            : <>
                                <AiOutlineLeftCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillRightCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-full ${index !== 2 && 'hidden'}`}>
                <h1 className='w-full text-[35px] font-bold pc:hidden text-primary'>
                    {client ? data[lang].testimonialTitle1 : data[lang].testimonialTitle2} <span className="text-sec">{data[lang].testimonialTitle3}</span>
                </h1>
                <p className='minTitle  pb-4   text-left   w-full md:mx-0'>
                Fintechracy's POS system is a true game-changer for our retail store. It's like having a dedicated shopping companion for our customers. The smooth, hassle-free shopping experience it provides has significantly boosted customer satisfaction, and the operational efficiency it offers is a game-changer for our business.
                </p>
                <div className='flexCenter gap-4 justify-start pc:mx-6'>
                    <img src={images.blog1} alt="" className='mpc:w-[90px] mpc:h-[90px] rounded-full w-14 h-14' />
                    <div className='flexBetween w-full'>
                        <div>
                            <p className='font-bold mpc:text-xl text-xs'>Chaouki</p>
                            <div className='flexCenter gap-2 mt-2'>
                                {[1,2].map( e => <img key={e} src={images.starYellow} className='mpc:w-5 w-3' alt='' />)}
                            </div>
                        </div>
                        <div className='flexCenter gap-6'>
                        {
                            lang === 'ar' ?
                            <>
                                <AiOutlineRightCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillLeftCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                            : <>
                                <AiOutlineLeftCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillRightCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-full ${index !== 3 && 'hidden'}`}>
                <h1 className='w-full text-[35px] font-bold pc:hidden text-primary'>
                    {client ? data[lang].testimonialTitle1 : data[lang].testimonialTitle2} <span className="text-sec">{data[lang].testimonialTitle3}</span>
                </h1>
                <p className='minTitle    mpc:text-start w-full pb-4 md:mx-0'>
                    Fintechracy's POS system has completely transformed the way we do business at our boutique. It feels like we have a personal shopping assistant for our clients. The seamless shopping experience it delivers has earned us loyal customers, and the operational efficiency it brings has optimized our business processes. It's an invaluable addition to our store.
                </p>
                <div className='flexCenter gap-4 justify-start pt-5 pc:mx-6'>
                    <img src={images.blog11} alt="" className='mpc:w-[90px] mpc:h-[90px] rounded-full w-14 h-14' />
                    <div className='flexBetween w-full'>
                        <div>
                            <p className='font-bold mpc:text-xl text-xs'>Chaouki</p>
                            <div className='flexCenter gap-2 mt-2'>
                                {[1,2,3,4].map( e => <img key={e} src={images.starYellow} className='mpc:w-5 w-3' alt='' />)}
                            </div>
                        </div>
                        <div className='flexCenter gap-6'>
                        {
                            lang === 'ar' ?
                            <>
                                <AiOutlineRightCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillLeftCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                            : <>
                                <AiOutlineLeftCircle onClick={dec} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                                <AiFillRightCircle onClick={inc} className='text-sec cursor-pointer mpc:text-[40px] text-2xl' />
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='relative pc:order-1 pc:my-6 pc:mt-12'>
            <img src={images.testimonial0} alt="" className='w-full relative z-30' />
            <img src={images.square} alt="" className='absolute bottom-0 left-2/4 -translate-x-2/4 w-3/4' />
        </div>
    </div>
  )
}