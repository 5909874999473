import data from './data'
import images from '../../constants/images'

export default function Landing({lang}) {
  return (
    <div className='text-center container mx-auto px-10 ph:px-5 pt-5'>
        <div className='md:text-[50px] text-[25px] font-bold md:leading-[80px] leading-[38px]'>
          <div className='text-primary'>{data[lang].landing1}</div>
          <div className='text-sec'>{data[lang].landing2}</div>
        </div>
        <p className='md:w-2/3 w-full text-text mx-auto md:leading-9 leading-7 md:text-[22.55px] text-[16px] my-6 mpc:mb-16'>{data[lang].landingp}</p>
        <img src={images.landing} alt="" className='  w-full md:w-[67%] mx-auto ' />
        <div className="mpc:w-[75%] w-full mx-auto md:my-36 md:mt-44 my-10">
          <h1 className=' md:text-[50px] text-[25px] font-bold text-center text-primary'>
              {data[lang].landing3} <span className='text-sec'>{data[lang].landing4}</span>
          </h1>
          <p className='minTitle  md:text-center w-full'>{data[lang].landingp2} <span className="text-sec">{data[lang].seeMore}</span></p>
        </div>
    </div>
  )
}