import React, { useEffect } from 'react'
import Blogs from './Blogs'
import Contact from '../../components/Contact'

export default function BlogPage({lang}) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
        <Blogs lang={lang} />
        <Contact lang={lang} />
    </>
  )
}
