import React from 'react'
import data from './data'
import images from '../../constants/images'
import { Link } from 'react-router-dom'

export default function Offer({lang}) {
  return (
    <div className='relative container md:px-10   md:w-[68%]   py-10 px-5 mx-auto '>
        <h1 className=' md:text-[38px] text-[25px] font-bold text-center text-primary md:my-36 my-14'>
            <span className='text-sec'>{data[lang].offerTitle1}</span> {data[lang].offerTitle2}
        </h1>
        <div className='bg-third md:rounded-md md:py-5  py-11'>
            <div className='flexBetween px-14'>
                <img src={images.star} className='md:w-[42px] w-[15px]' alt="" />
                <p className='text-sec md:text-[22px]  text-xs font-bold'>{data[lang].offerTitle3}</p>
                <img src={images.star} className='md:w-[42px] w-[15px]' alt="" />
            </div>
            <h1 className=' md:text-[35px] text-[25px] font-bold text-center text-primary my-6'>
                <span className='text-sec'>{data[lang].offerTitle4}</span> {data[lang].offerTitle5}
            </h1>
            <pre className='text-text md:w-3/5 w-[95%] mx-auto    text-center md:font-bold md:text-[22.55px] text-[17px] md:px-0 px-2 md:leading-10 leading-[30px]   mb-6'>
                {data[lang].offerText} <Link to={'/ftr-business'} className='text-sec'>{data[lang].seeMore}</Link>
            </pre>
            <img src={lang === 'ar' ? images.vecToLeft : images.vecToRight} className={`absolute pc:hidden ${lang === 'ar' ? ' right-96 -mt-10' : 'right-96 -mt-10'} -z-10`} alt="" />
            <div className='flexCenter relative w-fit mx-auto'>
                <img src={images.fb} className='absolute -left-20 -top-9' alt="" />
                <a href="#contact" className="btn mx-auto md:text-[22px] text-xs md:px-14 px-5 rounded-xl">{data[lang].contact}</a>
            </div>
        </div>
    </div>
  )
}