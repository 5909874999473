const data = {
  en: {
    title: 'Account Deletion Request',
    paragraph:
      "We're sorry to see you go. To proceed with the account deletion process, please provide your information, and a brief message explaining the reason for your decision. Your feedback is valuable to us.",
  },

  fr: {
    title: 'Demande de suppression de compte',
    paragraph:
      'Nous sommes désolés de vous voir partir. Pour procéder à la suppression de votre compte, veuillez fournir information et un bref message expliquant la raison de votre décision. Vos commentaires sont précieux pour nous.',
  },

  ar: {
    title: 'طلب حذف الحساب',
    paragraph:
      'نأسف لرؤيتك تغادر. لمتابعة عملية حذف الحساب، يرجى تقديم معلوماتك الشخصية، ورسالة قصيرة تشرح سبب قرارك. تعليقاتك تعتبر قيمة بالنسبة لنا.',
  },
};

export default data;
