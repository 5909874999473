import React from 'react'
import { useEffect } from "react"
import { useState } from "react"

export default function Faq({lang}) {

    const [active, setActive] = useState('store')

    useEffect(()=>{
        window.scrollTo(0, 0);
    })

  return (
    <div className="pb-60 md:w-10/12 w-11/12 mx-auto">
        <div className="mpc:w-[55%] w-4/5 mx-auto py-9 my-14">
          <h1 className=' md:text-4xl text-2xl font-bold text-center text-primary'>
            Frequently Asked Questions
          </h1>
        </div>
        <div className="grid grid-cols-3 md:gap-7 gap-4 mb-14">
            <p onClick={()=> setActive('store')} 
            className={`py-4 rounded-t-2xl cursor-pointer px-6 md:text-3xl text-center text-xl bg-[#E5E3FF] text-gray-500 ${active === 'store' && 'font-bold text-white bg-sec'}`} >FTR Store</p>
            <p onClick={()=> setActive('client')} 
            className={`py-4 rounded-t-2xl cursor-pointer px-6 md:text-3xl text-center text-xl bg-[#E5E3FF] text-gray-500 ${active === 'client' && 'font-bold text-white bg-sec'}`} >FTR Client</p>
            <p onClick={()=> setActive('business')} 
            className={`py-4 rounded-t-2xl cursor-pointer px-6 md:text-3xl text-center text-xl bg-[#E5E3FF] text-gray-500 ${active === 'business' && 'font-bold text-white bg-sec'}`} >FTR Business</p>
        </div>
    </div>
  )
}
