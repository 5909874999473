import React, { useEffect } from 'react'
import Landing from './Landing'
import Swiper from './Swiper'
import Offer from './Offer'
import ClientApp from './ClientApp'
import Benifits from './Benifits'
import Blog from './Blog'
import Testimonail from '../../components/Testimonail'
import Contact from '../../components/Contact'

export default function Home({lang}) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
        <Landing lang={lang} />
        <Swiper lang={lang} />
        <Offer lang={lang} />
        <ClientApp lang={lang} />
        <Benifits lang={lang} />
        <Blog lang={lang} />
        <Testimonail client lang={lang} />
        <Contact lang={lang} />
    </>
  )
}
