import React from 'react';
import Contact from '../../components/Contact';
import data from './data';

const UserDeletion = ({ lang }) => {
  return (
    <div>
      <div className="text-center container mx-auto px-10 ph:px-5 pt-0 md:pt-5  ">
        <div className="md:text-[50px] text-[27px] font-bold md:leading-[80px] leading-[38px]">
          <div className="text-primary ">{data[lang].title}</div>
        </div>
        <p className="md:w-2/3 w-[95%] text-text mx-auto md:leading-9 leading-7 md:text-[22.55px] text-[16px] my-6">
          {data[lang].paragraph}
        </p>
      </div>
      <Contact lang={lang} isUserDeletion={true} />
    </div>
  );
};

export default UserDeletion;
