import React from 'react'
import data from './data'
import images from '../../constants/images'
import img1 from '../../pic/img1.jpg'
import img2 from '../../assets/img2.jpg'
import img3 from '../../assets/img3.jpg'
import img4 from '../../assets/imgg4.jpg'
import img5 from '../../assets/img5.jpg'
import img6 from '../../assets/img6.jpg'


 
 

 

import '../../index.css'

export default function Swiper({lang}) {
  return (
    <div className=''>
        <div className='md:my-36 my-16 px-5  md:px-10'>
          <h1 className='md:text-[38px] text-[25px] font-bold text-center text-primary'>
              <span className='pc:block'>{data[lang].swiperTitle1}</span> <span className='text-sec pc:block'>{data[lang].swiperTitle2}</span>
          </h1>
          <p className='minTitle'>{data[lang].swiperMinTitle1}</p>
        </div>



        <div className=" w-[400px] h-[300px]   carousel-container">
      <div className="carousel-track  w-full   gap-[7rem]  md:gap-[2rem]   ">
      <div className="carousel-card     gap-[1rem]  md:gap-[2rem]  " >
      <img className=' w-[100px] md:w-[200px]   md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md' src={images.swiper1} alt="" />
      <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img3} alt="" />
            <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img4} alt="" />
            <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img5} alt="" />
          <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px]   object-cover rounded-md ' src={img6} alt="" />
            <img className=' w-[100px] md:w-[200px]  object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={images.swiper3} alt="" />
 
            <img className=' w-[100px] md:w-[200px]  object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={images.swiper5} alt="" />
 
    </div>
          <div className="carousel-card  gap-[1rem] object-center md:m-0 m-1  md:gap-[2rem]">
          
          <img className=' w-[100px] md:w-[200px]   md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md' src={images.swiper1} alt="" />
      <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img3} alt="" />
            <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img4} alt="" />
            <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={img5} alt="" />
          <img className=' w-[100px] md:w-[200px] object-center md:m-0 m-1 h-[200px] md:h-[340px]   object-cover rounded-md ' src={img6} alt="" />
            <img className=' w-[100px] md:w-[200px]  object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={images.swiper3} alt="" />
 
            <img className=' w-[100px] md:w-[200px]  object-center md:m-0 m-1 h-[200px] md:h-[340px] object-cover rounded-md ' src={images.swiper5} alt="" />
        </div>
      </div>
    </div>


        

       <div className="md:mt-36 md:mb-28 my-16">
        <h1 className=' md:text-[38px] text-[25px] font-bold text-center text-primary'>
              {data[lang].swiperTitle3} <span className='text-sec'>{data[lang].swiperTitle4}</span>
          </h1>
          <p className='minTitle'>{data[lang].swiperMinTitle2}</p>
        </div> 
        <div className='container mx-auto px-10 ph:px-5 pt-5'>
          <img src={images.worldmap} alt="" className=' w-full md:w-[70%] mx-auto' />
        </div>
    </div>
  )
}
