import React, { useEffect } from 'react'
import Landing from './Landing'
import ImgTextSection from '../../components/ImgTextSection'
import data from './data'
import images from '../../constants/images'
import ContactSection from './ContactSection'
import Testimonail from '../../components/Testimonail'
import Contact from '../../components/Contact'

export default function FTRBusiness({lang}) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
      <Landing lang={lang} />
      <ImgTextSection 
        title1={data[lang].secTitle1} 
        title2={data[lang].secTitle2}
        text={data[lang].secText1}
        img={images.imgText1}
        bottomImage={images.bi1}
        right 
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle3} 
        title2={data[lang].secTitle4}
        text={data[lang].secText2}
        img={images.imgText2}
        bottomImage={images.bi2}
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle5} 
        title2={data[lang].secTitle6}
        text={data[lang].secText3}
        img={images.imgText3}
        bottomImage={''}
        right
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle7} 
        title2={data[lang].secTitle8}
        text={data[lang].secText4}
        img={images.imgText4}
        bottomImage={images.bi3}
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle9} 
        title2={data[lang].secTitle10}
        text={data[lang].secText5}
        img={images.imgText5}
        bottomImage={''}
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle11} 
        title2={data[lang].secTitle12}
        text={data[lang].secText6}
        img={images.imgText6}
        bottomImage={images.bi4}
        right
        lang={lang}
      />
      <ImgTextSection 
        title1={data[lang].secTitle13} 
        title2={data[lang].secTitle14}
        text={data[lang].secText7}
        img={images.imgText7}
        bottomImage={images.bi5}
        lang={lang}
      />
      <ContactSection lang={lang} />
      <Testimonail lang={lang} />
      <Contact lang={lang} />
    </>
  )
}
