import { Link } from 'react-router-dom'
import data from './data'
import images from '../../constants/images'
import DownloadBtn from '../../components/DownloadBtn'

export default function ClientApp({lang}) {
  return (
    <div className=' my-16 md:-my-16 container mx-auto px-10  scale-100   md:scale-[70%]  ph:px-5 pt-5'>
        <div className='grid mpc:my-36 mpc:grid-cols-2 mpc:gap-20 gap-7'>
            <div className='flex justify-center mpc:gap-8 flex-col pc:text-center'>
                <h1 className=' md:text-[35px] text-[25px] font-bold text-primary'>
                    <span className='text-sec'>{data[lang].clientTitle1}</span> {data[lang].clientTitle2}
                </h1>
                <p className='minTitle  mpc:my-0 mb-4 mpc:text-start mpc:w-10/12 w-full mx-0'>{data[lang].clientText1}</p>
                <p className='minTitle   mpc:my-0 mb-4 mpc:text-start mpc:w-10/12 w-full mx-0'>{data[lang].clientText2} <Link to='/ftr-client' className='text-sec font-bold'>{data[lang].seeMore}</Link></p>
                <div className="pc:hidden">
                    <DownloadBtn client lang={lang} />
                </div>
            </div>
            <div className='relative'>
                <img src={images.client1} className='w-full' alt="" />
                <div className="mpc:hidden w-fit pc:mx-auto">
                    <DownloadBtn client lang={lang} />
                </div>
                <img src={lang === 'ar' ? images.vecToRight : images.vecToLeft} className={`absolute ${lang === 'ar' ? ' left-96 -mt-10' : 'right-96 -mt-10'} pc:hidden -z-10`} alt="" />
            </div>
        </div>
        <div className='grid   mpc:grid-cols-2 mpc:gap-28 gap-7 pc:mt-12'>
            <div className='mpc:order-1 order-2'>
                <img src={images.client2} className='w-full' alt="" />
                <div className="mpc:hidden w-fit pc:mx-auto">
                    <DownloadBtn store lang={lang} />
                </div>         
            </div>
            <div className='order-1 flex justify-center mpc:gap-3 flex-col  pc:text-center'>
                <h1 className=' md:text-[35px] text-[25px] font-bold text-primary'>
                    <span className='text-sec'>{data[lang].clientTitle3}</span> {data[lang].clientTitle4}
                </h1>
                <p className='minTitle   mpc:my-0 mb-4 mpc:text-start mpc:w-10/12 w-full mx-0'>{data[lang].clientText3}</p>
                <p className='minTitle   mpc:my-0 mb-4 mpc:text-start mpc:w-10/12 w-full mx-0'>{data[lang].clientText4} <Link to={'/ftr-store'} className='text-sec font-bold'>{data[lang].seeMore}</Link></p>
                <div className="pc:hidden">
                    <DownloadBtn store lang={lang} />
                </div>
            </div>
        </div>
    </div>
  )
}