 
import * as yup from 'yup'


export const ContactSchema = yup.object().shape({
    email: yup.string().email().required('email is a required field'),
    firstname: yup.string().required('firstname is a required field'),
    lastname: yup.string().required('lastname is a required field'),
    message: yup.string(),
    phonenumber: yup.number()
})