import { React, useEffect, useState } from 'react';
import data from './data';
import { Link } from 'react-router-dom';
import images from '../constants/images';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { ContactSchema } from '../Validation/ContactVal';
import { useFormik } from 'formik';
import Massege from './Messge';
import LoadingBtn from './LoadingBtn';

export default function Contact({ lang, isUserDeletion }) {
  const [phone, setPhone] = useState('');
  const [msg, setMsg] = useState('');
  const [isLoading, setisLoading] = useState(false);

  function onSubmit() {
    setisLoading(true);
    axios
      .post('https://api.fintechracy.org/api/v1/interested_guys', {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone: phone,
        message: values.message,
      })
      .then((response) => {
        const { data } = response;
        setMsg('setMsg');
        setisLoading(false);
        console.log('fromm servv ' + data);
      })
      .catch((err) => {
        console.error();
        setisLoading(false);
      });
  }

  const {
    setValues,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,

    handleSubmit,
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      phonenumber: '',
    },
    validationSchema: ContactSchema,
    onSubmit,
  });

  return (
    <div className={`gap-0 ${isUserDeletion ? '' : 'pt-20'}`} id="contact">
      <h1 className="md:text-4xl text-2xl font-bold text-sec text-center mb-5">
        {isUserDeletion ? '' : data[lang].contact}
      </h1>
      <div
        className={`  flexCenter  md:flex-row flex-col-reverse  mpc:gap-2 gap-3 relative md:my-8 mpc:py-8 pc:py-5 my-0 ${
          lang === 'ar' && 'flex-row-reverse'
        }`}
      >
        <img
          src={images.contact1}
          className="absolute pc:hidden right-0  h-[75%] "
          alt=""
        />
        <img
          src={images.contact2}
          className="absolute w-2/4 mpc:hidden right-0 h-[70%] "
          alt=""
        />
        <div className="  -mr-24 scale-100  md:scale-[60%] flexCenter flex-row md:flex-col mpc:gap-11  z-30 boxShadow2 mpc:px-6 px-3 gap-4 py-4  md:py-6 mpc:py-11 rounded-3xl bg-white">
          <Link>
            <img src={images.facebook} className="pc:w-[25px] w-12" alt="" />
          </Link>
          <Link>
            <img src={images.instagram} className="pc:w-[25px] w-12" alt="" />
          </Link>
          <Link>
            <img src={images.linkdin} className="pc:w-[25px] w-12" alt="" />
          </Link>
        </div>

        <form
          onSubmit={handleSubmit}
          className="mpc:w-2/3 scale-100  md:scale-[75%]  w-[95%] md:w-4/5 mpc:px-9 p-4 mpc:py-11 bg-white boxShadow2 relative z-30 rounded-3xl"
        >
          <div className="w-full grid mpc:grid-cols-2 mpc:gap-6 gap-3 mpc:mb-6 mb-3">
            {data[lang].contactForm.map((i) => (
              <div key={i.label}>
                <label
                  className="text-primary block  text-[16px] mpc:mb-6 mb-3"
                  htmlFor={i.label}
                >
                  {i.label}
                </label>
                {i.type !== 'phone' ? (
                  <>
                    <input
                      type="text"
                      value={values?.i?.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={i.value}
                      className={` ${
                        i?.value == 'email'
                          ? errors.email && touched.email
                            ? ' border-[#fc8181] text-red-500  border-[2px]'
                            : ''
                          : i?.value == 'lastname'
                          ? errors.lastname && touched.lastname
                            ? ' border-[#fc8181] text-red-500  border-[2px]'
                            : ''
                          : errors.firstname && touched.firstname
                          ? ' border-[#fc8181] text-red-500  border-[2px]'
                          : ''
                      } w-full h-14 outline-none mpc:px-8
                        mpc:py-6 py-1 px-3 border-2 border-[#E8E8E8] rounded-xl pc:rounded-md pc:text-[12px]  `}
                      id={i.label}
                      placeholder={i.placeholder}
                    />
                    {i?.value == 'email'
                      ? errors.email &&
                        touched.email && (
                          <p className=" pt-1 px-1 text-xs text-red-400">
                            {' '}
                            {errors.email}{' '}
                          </p>
                        )
                      : i?.value == 'lastname'
                      ? errors.lastname &&
                        touched.lastname && (
                          <p className=" pt-1 px-1 text-xs text-red-400">
                            {' '}
                            {errors.lastname}{' '}
                          </p>
                        )
                      : errors.firstname &&
                        touched.firstname && (
                          <p className=" pt-1 px-1 text-xs text-red-400">
                            {' '}
                            {errors.firstname}{' '}
                          </p>
                        )}
                  </>
                ) : (
                  <>
                    <div className=" md:flex hidden">
                      <PhoneInput
                        country={
                          lang === 'en' ? 'us' : lang === 'fr' ? 'fr' : 'dz'
                        }
                        inputStyle={{ height: '3.5px' }}
                        value={phone}
                        onChange={(newPhone) => setPhone(newPhone)}
                        id="phonenumber"
                        placeholder={i.placeholder}
                      />
                    </div>
                    <div className=" flex md:hidden">
                      <PhoneInput
                        country={
                          lang === 'en' ? 'us' : lang === 'fr' ? 'fr' : 'dz'
                        }
                        value={phone}
                        onChange={(newPhone) => setPhone(newPhone)}
                        id="phonenumber"
                        className={' py-0'}
                        style={{ height: '50px' }}
                        placeholder={i.placeholder}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="w-full">
            <label
              className="text-primary block mpc:text-2xl text-[16  px] mpc:mb-6 mb-3"
              htmlFor="message"
            >
              {data[lang].message}
            </label>
            <textarea
              name="message"
              value={values.message}
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              className="w-full items-start justify-start outline-none p-3 border-2 border-[#E8E8E8] rounded-xl pc:rounded-md mpc:h-48 h-[70px] resize-none"
              id="message"
            />
          </div>
          <button
            type="submit"
            className="btn relative text-center flex items-center justify-center pc:text-sm mt-6 md:w-0 w-[40%] h-12 md:px-20 px-4 mx-auto"
          >
            {data[lang].send}
            <LoadingBtn isLoading={isLoading} small={true} />
          </button>
        </form>
      </div>
      <Massege msg={msg} setMsg={setMsg} />
    </div>
  );
}
