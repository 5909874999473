import { Link } from 'react-router-dom'
import images from '../../constants/images'
import data from './data'

export default function JoinApp({ lang,id }) {
  return (
    <div className=' py-20 px-10 bg-sec flexCenter gap-6 pc:flex-col md:my-44 my-16'>
       <div className=' flex  flex-col items-center md:gap-0 gap-4 justify-between  md:scale-[66%] scale-100  md:flex-row '>
  <p className='w-8/12 text-[32px] pc:text-xl pc:w-[75%]  pc:leading-[2.5] pc:text-center font-bold leading-[2] text-white'>
          { id % 2 !== 0 ? data[lang].joinCommStore : data[lang].joinCommClient }
        </p>
        <div className='w-2/12 pc:w-6/12'>
            <Link to={''} className='flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg mb-4'>
               <img src={images.playStore} className=' w-14 pc:w-10' alt="" />
                <span className='text-2xl pc:text-sm text-white font-bold'>Play Store</span>
            </Link>
            <Link to={''} className='flexBetween pc:w-full pc:gap-3 p-3 border-[0.75px] border-white rounded-lg'>
               <img src={images.appStore} className=' w-14 pc:w-10' alt="" />
               <span className='text-2xl pc:text-sm text-white font-bold'>App Store</span>
            </Link>
        </div>
       </div>
      
    </div>
  )
}
